.section-title {
  text-align: initial;
  margin-bottom: 2%;
  font-weight: 800;
  font-size: 1.5rem;
  line-height: 2rem;
  font-family: "MontserratExtraBold";
}
.section-title-div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  align-items: center;
  padding: 0;
}
.section-title-div a {
  padding: 1rem;
  padding-right: 0;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1rem;
  letter-spacing: 0.1em;
  text-decoration-line: underline;
  color: #000000;
}
.section-title-div a:hover {
  color: #fbb040;
}
.section-title-div h4 {
  margin: 0;
  font-family: "MontserratExtraBold";
}
.delivery-container {
  padding: 2rem 1rem;
  padding-bottom: 0rem;
}
/* .delivery-option {
  padding: 2% !important;
  padding-bottom: 2% !important;
} */
.dealOfTheDay-container-wrap {
  padding: 2% 0.5% 2% 0.5%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.dealOfTheDay-container-wrap .headring-container {
  text-align: left;
  padding: 0% 1rem;
}

.dealOfTheDay-carousel-arrow {
  display: flex;
  align-items: center;
  padding: 0 0.1rem;
}
.dealOfTheday-row {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0%;
}

.carousel-arrow-btn {
  cursor: pointer;
  width: 100%;
  padding: 0 !important;
  background: transparent !important;
  border: none !important;
  /* font-size: 5rem; */
}
.deal-card {
  border: 1px solid #d9d9d9 !important;
  border-radius: 20px !important;
  margin: 1rem 1rem 1rem 0;
  height: 100%;
}
.deal-card .card-img-top {
  width: auto;
  height: 200px !important;
  margin: auto;
  padding-top: 1rem;
}
.deal-card-small-text {
  font-style: italic;
  font-weight: 300;
  font-size: 0.8rem;
  line-height: 1rem;
  color: #626262;
}
.card-text {
  margin: 0;
}
.deal-card .ref-btn {
  background-color: #fbb040;
  border: 1px solid #fbb040;
  border-radius: 5px;
  color: #231f20;
  padding: 0.5rem 2rem;
  font-weight: 600;
  font-size: 0.9rem;
  line-height: 1rem;
  text-align: center;
  letter-spacing: 0.1em;
  color: #231f20;
}
.card-image-heading {
  display: flex;
  position: absolute;
  justify-content: space-between;
  padding: 1.5rem 0;
  align-items: flex-start;
  width: 100%;
}
.card-image-heading p {
  margin-bottom: 3%;
  padding-left: 10%;
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 1rem;
  text-transform: uppercase;
  color: #817f7f;

  /* padding: 0.1rem 1rem;
  padding-left: 1rem;
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 1rem;
  text-transform: uppercase;
  border-radius: 0 5px 5px 0;
  color: #000000;
  background-color: #fbb040; */
}
.brand-container {
  display: flex;
  flex-wrap: wrap;
}
.brand-row .brand-card {
  height: 100%;
  width: 100%;
  padding: 10%;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}
.brand-row .brand-card:hover {
  background: #f5f5f5;
}
.brand-row .brand-card img {
  max-height: 10rem;
  max-width: 100%;
  margin: auto;
}
.brand-row .brand-card p {
  margin: 3% 0;
  font-weight: 400;
  font-size: 0.7rem;
  line-height: 1rem;
  letter-spacing: 0.1em;
}
.card-price-title {
  display: flex;
  align-items: baseline;
  justify-content: center;
  font-size: 2.5rem;
  line-height: 0.2rem;
  padding: 0;
  color: #000000;
  margin: 0;
  font-weight: 600;
}
.card-price-title .h5 {
  font-weight: 600;
}
.card-price-title span {
  font-weight: 400;
  font-size: 1.2rem;
}
.card-body .old-price {
  font-weight: 600;
  font-size: 0.8rem;
  margin-bottom: 0.1rem;
}
.deal-card .ref-btn:hover {
  background-color: transparent;
  border: 1px solid #fbb040;
  color: #fbb040;
}

.react-multiple-carousel__arrow {
  display: none;
}

/* banner Image */
.carousel-caption {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 4rem 7rem;
  padding-right: 0;
  position: relative;
  left: 0;
  right: 0;
  bottom: 0;
}
.image-col img{
  height: 100%;
}
.carousel-caption h1 {
  font-size: 3.25rem;
  line-height: 4.5rem;
  margin: 0;
  font-weight: 900;
  text-align: left;
  color: #000000;
}
.carousel-caption p {
  font-size: 2rem;
  line-height: 2.5rem;
  margin: 1rem 0;
  font-weight: 200;
  text-align: left;
  color: #000000;
  font-family: "RobotoThin";
}

.carousel-caption button {
  background: #fbb040;
  border-radius: 1.5rem;
  border: none;
  /* margin-left: auto; */
  padding: 0.7rem 1.8rem;
  font-size: 0.8rem;
  line-height: 1rem;
  font-weight: 600;
  color: #231f20;
}
.carousel-caption button:hover {
  background: #fbb040;
}
.promotion {
  margin-bottom: 1rem !important;
}

.prompt-card {
  padding: 7% 5%;
  margin: 0% !important;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
}
.prompt-card-heading.mobile-heding {
  display: none;
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.5rem;
  text-transform: uppercase;
  color: #000000;
  padding: 0% 7%;
}
.prompt-card-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

.prompt-card-heading {
  display: flex;
  align-items: baseline;
  font-weight: 700;
  line-height: 2.5rem;
}
.prompt-card-heading h1 {
  margin: 0;
  font-weight: 700;
  font-size: 2.25rem;
  line-height: 42px;
}
.prompt-card-heading span {
  margin-left: 5px;
  margin: 0;
  font-size: 1.2rem;
}
.prompt-card-title {
  padding: 6% 0 12% 0;
}

.prompt-card-details h5 {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.5rem;
  color: #000000;
}
.prompt-card-details p {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;
  color: #000000;
}
.prompt-card button {
  background: #fbb040;
  border-radius: 5px;
  border: none;
  color: #231f20;
  font-weight: 500;
  padding: 1rem 1.5rem;
  font-size: 0.9rem;
  line-height: 1px;
}
.prompt-card button:hover {
  background: #fbb040;
}
.prompt-card-img {
  display: flex;
  align-items: center;
}

.carousel-indicators {
  display: flex;
  align-items: center;
  bottom: -1.5rem !important;
  margin: 0;
}
.carousel-indicators [data-bs-target] {
  padding: 0.1% !important;
  height: 5px !important;
  margin: 0 0.5% !important;
  width: 5px !important;
  border-radius: 50% !important;
  border: 1px solid #817f7f !important;
  background-color: #ffffff !important;
}
.carousel-indicators button.active {
  background-color: #fbb040 !important;
  border: 1px solid #fbb040 !important;
}

.mobile-slide-caption {
  padding: 5%;
}
.mobile-slide-caption img {
  width: 40%;
}
.mobile-slide-caption p {
  margin: 5% 0%;
  font-family: "MontserratSemiBold";
  color: #626262;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.75rem;
  text-align: center;

  color: #231f20;
}
.mobile-slide-caption button {
  background-color: #fbb040;
  border: none;
  border-radius: 25px;
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 0.8rem;
  line-height: 1rem;
  letter-spacing: 0.1em;
  padding: 1rem 2rem;
  color: #231f20;
}
.react-multi-carousel-list {
  padding-bottom: 3%;
}

.custom-dot-list-style li button {
  background-color: #d9d9d9;
  border: none;
  width: 0.5rem;
  height: 0.5rem;
}
.custom-dot-list-style li.react-multi-carousel-dot--active button {
  background-color: #fbb040;
  border: none;
  width: 0.5rem;
  height: 0.5rem;
}

.small-heading-underline {
  width: 5%;
  height: 3px;
  color: #fbb040;
  /* border: 3px solid #FBB040; */
}
#borderLeft {
  width: 2%;
  border-bottom: 3px solid #fbb040;
  position: relative;
  right: 19%;
  left: 0%;
  padding: 2px;
}
.card-body {
  padding-top: 0 !important;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  max-height: 150px;
}
.card-body p {
  line-height: 0.5rem;
}
.carousel-item {
  opacity: 0.1;
  transition: opacity 0.5s ease-in-out;
}

.active.carousel-item {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}
@media (max-width: 1441px) {
  .delivery-container {
    padding: 1rem !important;
    padding-bottom: 0% !important;
  }
  .dealOfTheDay-container-wrap .headring-container {
    text-align: left;
    padding: 0 1rem;
  }
  .section-title-div {
    padding: 0;
  }
}

@media (max-width: 1024px) {
  .dealOfTheDay-carousel-arrow {
    display: none;
  }
}

@media (max-width: 768px) {
  .carousel-caption h1 {
    font-size: 2.25rem;
    line-height: 2.75rem;
  }
  .carousel-caption p {
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 0.5rem 0;
  }
  .carousel-caption {
    padding: 2rem 1rem;
  }
  .custom-dot-list-style {
    bottom: -1.5rem !important;
  }
  .dealOfTheDay-container-wrap {
    padding: 2rem 0.5% 2rem 0.5%;
  }

  .card-price-title {
    display: flex;
    align-items: baseline;
    justify-content: center;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 0.5rem;
    padding: 0;
    color: #000000;
  }
  .card-price-title span {
    font-weight: 600;
    font-size: 0.9rem;
  }
  .card-price-title {
    padding: 0;
  }
  .deal-card .ref-btn {
    margin-top: 0.1rem;
  }
  .card-text {
    margin: 0;
    margin-top: 0.1rem;

    font-weight: 400;
    font-size: 0.8rem !important;
    text-align: center;

    color: #000000;
  }
  .card-title {
    font-size: 1.25rem !important;
  }
  .react-multi-carousel-list {
    padding: 2rem;
    padding-top: 0;
    padding-bottom: 2rem;
  }
  .section-title-div {
    padding: 0%;
  }
  .dealOfTheday-row {
    padding: 0;
  }
  .dealOfTheDay-container-wrap .headring-container {
    text-align: left;
    padding: 0% 0.9rem;
  }
  .delivery-container {
    padding: 10% 4% !important;
    padding-bottom: 2% !important;
  }
  /* .delivery-option {
    padding: 10% 4% !important;
    padding-bottom: 2% !important;
  } */
  
  #borderLeft {
    width: 8%;
  }
  .section-title {
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-family: "MontserratExtraBold";
  }
  .section-title-div a {
    font-size: 0.75rem;
    line-height: 1rem;
  }
  .brand-row .brand-card p {
    margin: 3% 0;
    font-weight: 400;
    font-size: 0.5rem;
    line-height: 0.75rem;
    letter-spacing: 0.1em;
  }
}
@media (max-width: 576px) {
  .carousel-caption {
    padding: 2rem 0.5rem;
  }
  .promotion-col {
    padding-top: 0.5rem !important;
    padding-bottom: 0rem !important;
    margin-bottom: 0.5rem !important;
  }

  #borderLeft {
    width: 8%;
  }
  .section-title {
    font-size: 1rem;
    line-height: 1.25rem;
    font-family: "MontserratExtraBold";
  }
}
