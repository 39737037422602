@font-face {
  font-family: "RobotoRegular"; /*Can be any text*/
  src: local("RobotoRegular"),
    url("./assets/fonts/Roboto-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "RobotoThin"; /*Can be any text*/
  src: local("RobotoThin"),
    url("./assets/fonts/Roboto-Thin.ttf") format("truetype");
}
@font-face {
  font-family: "RobotoBold"; /*Can be any text*/
  src: local("RobotoBold"),
    url("./assets/fonts/Roboto-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "MontserratRegular"; /*Can be any text*/
  src: local("MontserratRegular"),
    url("./assets/fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "MontserratMedium"; /*Can be any text*/
  src: local("MontserratMedium"),
    url("./assets/fonts/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "MontserratSemiBold"; /*Can be any text*/
  src: local("MontserratSemiBold"),
    url("./assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "MontserratBold"; /*Can be any text*/
  src: local("MontserratBold"),
    url("./assets/fonts/Montserrat-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "MontserratExtraBold"; /*Can be any text*/
  src: local("MontserratExtraBold"),
    url("./assets/fonts/Montserrat-ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: "MontserratBlack"; /*Can be any text*/
  src: local("MontserratBlack"),
    url("./assets/fonts/Montserrat-Black.ttf") format("truetype");
}

@font-face {
  font-family: "PoppinsRegular"; /*Can be any text*/
  src: local("PoppinsRegular"),
    url("./assets/fonts/Poppins-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "InterRegular"; /*Can be any text*/
  src: local("InterRegular"),
    url("./assets/fonts/Inter-Regular.ttf") format("truetype");
}
body {
  margin: 0;
}
#borderLeft {
  width: 2%;
  border-bottom: 3px solid #fbb040;
  position: relative;
  right: 19%;
  left: 0%;
  padding: 2px;
}
@media (max-width: 768px) {
  #borderLeft {
    width: 8%;
  }
}
@media (max-width: 576px) {
  #borderLeft {
    width: 8%;
  }
}
/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
