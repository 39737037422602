.about-us-seller-reverse {
  background-image: url("../../assets//images/greyBackgroud.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 138px;
  transform: scaleY(-1);
}
.about-seller-brand,
.about-seller-grow-wrap,
.aboutus-seller-help-map {
  background-color: #f0f1f1;
}
.about-us-seller {
  background-image: url("../../assets//images/greyBackgroud.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 138px;
}
.section-title-seller {
  font-family: "MontserratBold";
  font-size: xx-large;
}
.section-title-seller img {
  vertical-align: baseline;
}
.section-title-seller span {
  color: #fdc345;
}
.aboutus-seller-banner-text-col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.aboutus-seller-banner-text-col.right h4 {
  text-align: left;
}
.aboutus-seller-banner-text-col.right h5 {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 1.9rem;
  line-height: 2.75rem;
  color: #75809e;
  text-align: left;
  margin-bottom: 2rem;
}

.aboutus-seller-banner-text-col.right p {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.5rem;
  text-align: left;
  color: #75809e;
}
.about-seller-grow h1 {
  text-align: left;
  font-family: "MontserratBold";
  font-style: normal;
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 3.8rem;
  color: #1d1d1d;
}
.about-seller-grow-card {
  text-align: left;
  padding: 5% 10%;
  height: 100%;
  display: flex;
  font-family: "InterRegular";
  flex-direction: column;
}
.about-seller-grow-card img {
  width: 100%;
  height: 16rem;
  margin: 1rem;
}
.about-seller-grow-card h6 {
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.9rem;
  color: #fdc345;
}
.about-seller-grow-card p {
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  display: flex;
  color: #75809e;
}

.about-us-help-map {
  background-image: url("../../assets//images/weight-curve.png");
  background-repeat: no-repeat;
  background-size: 100% 101%;
  height: 100px;
  margin-top: 5%;
}
.aboutus-seller-help-map {
  text-align: left;
}
.aboutus-seller-help-map .help-map-text-col {
  padding: 0% 5%;
  display: flex;
  align-items: center;
}
.aboutus-seller-help-map .help-map-text-col .line-item {
  height: 10%;
  width: 50%;
  left: calc(50% - 57px / 2 - 556px);
  top: 167px;
  border-top: 2.5px solid #75809e;
}
.aboutus-seller-help-map .help-map-text-col h1 {
  font-family: "PoppinsRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 29px;
  line-height: 44px;
  padding: 7% 5%;
  color: #75809e;
}
.aboutus-seller-help-map .help-map-img-col img {
  width: 100%;
}

.aboutus-seller-kpi .kpi-text-col,
.aboutus-seller-tech-details .tech-details-text-col {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.aboutus-seller-kpi .left,
.aboutus-seller-tech-details .left {
  padding: 1rem 0% 1rem 8rem;
}
.aboutus-seller-kpi .right,
.aboutus-seller-tech-details .right {
  padding: 1rem 8rem 1rem 0%;
}

.aboutus-seller-kpi .kpi-text-col h1,
.aboutus-seller-tech-details .tech-details-text-col h1 {
  font-family: "MontserratBold";
  font-style: normal;
  font-weight: 700;
  font-size: 41px;
  line-height: 50px;
  color: #1d1d1d;
  margin-bottom: 2rem;
}

.aboutus-seller-kpi .kpi-text-col .kpi-list-row img,
.aboutus-seller-tech-details .tech-details-text-col .tech-details-list-row img {
  height: 1.5rem;
  width: 1.5rem;
}

.aboutus-seller-kpi .kpi-text-col .kpi-list-row h4,
.aboutus-seller-tech-details .tech-details-text-col .tech-details-list-row h4 {
  font-family: "InterRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.75rem;
  color: #000000;
}
.aboutus-seller-kpi .kpi-text-col .kpi-list-row p,
.aboutus-seller-tech-details .tech-details-text-col .tech-details-list-row p {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #75809e;
}

.aboutus-seller-kpi .kpi-img-col,
.aboutus-seller-tech-details .tech-details-img-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.aboutus-seller-kpi .kpi-img-col img,
.aboutus-seller-tech-details .tech-details-img-col img {
  width: 100%;
}
.aboutus-seller-tech-details .tech-details-text-col {
  background-image: url("../../assets//images/ellipse.png");
  background-repeat: no-repeat;
  background-size: auto 60%;
  background-position-x: right;
  background-position-y: bottom;
}
.aboutus-seller-tech-details .tech-details-img-col {
  background-image: url("../../assets//images/ellipse-2.png");
  background-repeat: no-repeat;
  background-size: auto 30%;
  background-position-x: left;
  background-position-y: bottom;
}
.get-started {
  margin-left: 6rem;
}
@media (max-width: 1024px) {
}
@media (max-width: 992px) {
  .aboutus-seller-kpi .left,
  .aboutus-seller-tech-details .left {
    padding: 5% 10%;
  }
  .aboutus-seller-kpi .right,
  .aboutus-seller-tech-details .right {
    padding: 5% 10%;
  }
}
@media (max-width: 768px) {
  .about-seller-grow-card {
    padding: 0%;
  }
  .about-seller-grow-card img {
    margin: 0rem;
  }
  .about-us-seller {
    height: 100px;
  }
  .aboutus-seller-kpi .kpi-text-col .section-link,
  .aboutus-seller-tech-details .tech-details-text-col .section-link {
    margin-left: auto;
    margin-right: auto;
  }
  .section-title-seller.mobile {
    font-size: 1.5rem;
  }
  .section-title-seller.mobile img {
    height: 1.5rem;
    font-size: 1.5rem;
  }
  .aboutus-seller-banner-text-col.right h5 {
    font-size: 1.25rem;
    line-height: 2rem;
    color: #75809e;
    text-align: left;
    margin-bottom: 2rem;
  }
  .aboutus-seller-banner-text-col.right p {
    font-size: 0.75rem;
    line-height: 1rem;
    text-align: left;
  }
  .about-us-seller-reverse {
    height: 50px;
  }
  .about-seller-brand .container {
    padding-bottom: 1rem !important;
  }
  .about-seller-grow.container {
    padding: 1rem 1rem !important;
  }
  .about-seller-grow h1 {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
  .about-seller-grow h1 img {
    height: 2rem;
  }
  .aboutus-seller-help-map .help-map-text-col h1 {
    font-size: 1.25rem;
    line-height: 2rem;
    padding: 5% 3%;
  }
  .about-us-help-map {
    height: 50px;
  }
  .aboutus-seller-kpi .kpi-text-col h1,
  .aboutus-seller-tech-details .tech-details-text-col h1 {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
}
@media (max-width: 576px) {
}
