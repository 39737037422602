



.action-btn {
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 1rem;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #231f20;
  border-radius: 0;
  padding: 0.5rem 1.5rem;
  margin-left: 2%;
  border-radius: 0.2rem;
  width: 100%;
}
.action-btn.close-btn {
  height: 100%;
  background-color: transparent;
  border: 1px solid #626262;
  margin-left: auto;
  color: #626262;
}

.action-btn.accept-btn {
  height: 100%;
  background-color: transparent;
  border: 1px solid #039487;
  color: #039487;
}
.action-btn.reject-btn {
  height: 100%;
  background-color: transparent;
  border: 1px solid #ff4f4f;
  color: #ff4f4f;
}
.action-btn.submit-btn {
  background-color: transparent;
  border: 1px solid #fbb040;
  color: #fbb040;
}
@media (max-width: 768px) {
  .action-btn.accept-btn {
    padding: 1rem;
    padding-left: 0.5rem;
    margin: 0;
  }
  .action-btn.reject-btn {
    padding: 0.5rem;
    padding-left: 0.5rem;
    margin: 0;
  }
}