.menu-btn {
  background-color: transparent;
  display: flex;
  border: none;
  align-items: center;
  text-transform: uppercase;
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #ffffff;
  cursor: pointer;
}
/* .menu-btn:hover {
  background-color: transparent;
  color: #ffffff;
} */

.menu-btn:hover,
.menu-btn:active {
  background-color: transparent !important;
  color: #ffffff !important;
}

.menu-btn span {
  margin-left: 1rem;
}

.menu-btn svg,
.sub-menu-btn svg {
  width: 1.1rem;
  height: 1.1rem;
}

.sub-menu-btn {
  padding-left: 2.8rem;
  background-color: transparent;
  display: flex;
  border: none;
  align-items: center;
  text-transform: uppercase;
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 1.25rem;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #ffffff;
  cursor: pointer;
}
/* .menu-btn:hover {
  background-color: transparent;
  color: #ffffff;
} */

.sub-menu-btn:hover,
.sub-menu-btn:active {
  background-color: transparent !important;
  color: #ffffff !important;
}

.sub-menu-btn span {
  margin-left: 1rem;
}

.admion-plane-menu-div {
  padding: 2.5rem 0;
}
@media (max-width: 768px) {
  .admion-plane-menu-div {
    margin-top: 1rem;
    padding: 1rem 0;
  }
}
