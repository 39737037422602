.bankInfo {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 1rem 1.5rem;
  text-align: left;
}
.bankInfo .heading-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin: 0;
  margin-bottom: 1rem;
}
.bankInfo .heading-div h5 {
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: #231f20;
  width: max-content;
}
.bankInfo .heading-div p {
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 1rem;
  text-align: right;
  color: #00b3f7;
  width: max-content;
  margin: 0;
  padding: 0;
}
.bankInfo  {
  display: flex;
  justify-content: flex-end;
}

.bankInfo  h6 {
  font-weight: 700;
  font-size: 0.9rem;
  line-height: 1rem;
  letter-spacing: 0.1em;
  color: #000000;
}
.bankInfo  p {
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 1rem;
  color: #231f20;
}
.bankInfo  p span {
  color: #807f7f;
}
.bankInfo  p  .bold-span {
  color: #231f20;
}
.bankInfo .action-button-box {
  display: flex;
  justify-content: center;
}
@media (max-width: 768px) {
  .bankInfo {
    padding: 1rem;
  }
  .bankInfo-container{
    margin: 1.5rem 0;
  }
}