.terms-main-section {
  background-color: #f8f8f8;
}
.terms-banner-main-div {
  padding: 4%;
  color: #626262;
  background-color: #f1f8ff;
  /* background-image: url("../../assets/images/Brands/BrandHomeBanner.png"); */
  background-repeat: no-repeat;
  background-size: cover;
}
.terms-banner-main-div h2 {
  margin: 0;
  font-size: 1.6rem;
  line-height: 2rem;
  color: #000000;
  font-family: "MontserratRegular";
  font-weight: 800;
}
.terms-banner-main-div p {
  margin: 0;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1rem;
}
.terms-main-section p a {
  /* margin: 0; */
  color: #000000;
  text-decoration: none;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1rem;
  font-family: "MontserratRegular";
}
.terms-conatiner {
  text-align: left;
}
.terms-conatiner h2{
  font-family: "InterRegular";
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.75rem;
  letter-spacing: 0em;
  color: #000000;
  border-bottom: 2px solid  #D9D9D9;
  ;
  padding-bottom: 0.75rem;
  width: max-content;
  margin-bottom: 1rem;
}

.terms-conatiner h6 {
  font-family: "RobotoRegular";
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  letter-spacing: 0em;
  margin: 0;
  color: #000000;
  text-decoration: underline;

}
.terms-conatiner p {
  font-family: "RobotoRegular";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0em;
  margin-bottom: 1rem;
}
.terms-conatiner p span{
  font-weight: 800;
}
.terms-conatiner p ul {
  padding-left: 1rem;
  margin: 0;
}
.terms-conatiner p ol {
  padding-left: 1rem;
  margin: 0;
}
.terms-conatiner p ol li span{
  font-weight: 800;
}
@media (max-width: 768px) {
}
