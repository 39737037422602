.address {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 1rem 1.5rem;
  text-align: left;
}
.address .heading-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin: 0;
  margin-bottom: 1rem;
}
.address .heading-div h5 {
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: #231f20;
  width: max-content;
  padding: 0;
  margin: 0;
}
.address .heading-div p {
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 1rem;
  text-align: right;
  color: #00b3f7;
  width: max-content;
  margin: 0;
  padding: 0;
}
.address .right {
  display: flex;
  justify-content: flex-end;
  padding-right: 0;
}
.address .left {
  padding-left: 0;
}
.address .left h6 {
  font-weight: 700;
  font-size: 0.9rem;
  line-height: 1rem;
  letter-spacing: 0.1em;
  color: #000000;
}
.address .left p {
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 1rem;
  color: #231f20;
}
.address .left p span {
  color: #807f7f;
}
.address .left p .bold-span {
  color: #231f20;
}
.address .action-button-box {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
@media (max-width: 768px) {
  .address {
    padding: 1rem 0.75rem;
  }
  .address .heading-div {
    margin-bottom: 0.25rem;
  }
  .address .heading-div h5 {
    font-size: 1rem;
    line-height: 1.25rem;
  }
  .address .heading-div p {
    font-size: 0.75rem;
    line-height: 0.9rem;
  }
}
