.admin-panel-section h1 {
  font-family: "MontserratRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 1.75rem;
  line-height: 2.25rem;
  text-align: left;
  color: #231f20;
  text-transform: uppercase;
  padding: 0;
  width: max-content;
}

.admin-panel-section h4 {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.75rem;
  text-align: left;
  color: #231f20;
}

.admin-panel-section {
  background-color: #f5f5f5;
}

.admin-panel-menu-col {
  background: linear-gradient(269.9deg, #ff9900 -0.78%, #fbb040 99.93%);
  border-radius: 0px 50px 0px 0px;
  padding: 2rem 0.25rem;
}
/* .admin-panel-content-col {
  height: 690px;
} */
.admin-panel-content-col .header-row .header-menu {
  margin-left: auto;
  width: max-content;
  padding: 2rem 0rem;
}
.admin-panel-content-col .header-row .header-menu button {
  background-color: transparent;
  border: none;
}
.admin-panel-content-col .header-row .header-menu svg {
  height: 2rem;
  width: 2rem;
}
.admin-panel-content-col .header-row .header-menu img {
  height: 2rem;
  margin-left: 3rem;
  width: 2rem;
}
.admin-panel-menu-col .logo-div {
  padding: 0%;
}
.admin-panel-menu-col .logo-div img {
  width: 55%;
}

.admin-panel-menu-col .logout-btn {
  background-color: transparent;
  display: flex;
  border: none;
  align-items: center;
  text-transform: uppercase;
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 1.5rem;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #ffffff;
  margin-top: 8rem;
  text-decoration: none;
  padding: 1.5rem;
}
.admin-panel-menu-col .logout-btn span {
  margin-left: 1rem;
}

.title-button-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.view-switch-btn {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 400;
  background: #ff9900;
  border: 1px solid #ff9900;
  border-radius: 5px;
  color: #ffffff !important;
  width: max-content;
  margin-left: auto;
  margin-right: 0.8rem;
}
.view-switch-btn:hover {
  background: #ff9900;
  border: 1px solid #ff9900;
  border-radius: 5px;
  color: #ffffff !important;
  width: max-content;
  margin-left: auto;
}
.view-switch-btn:active {
  background-color: #ff9900 !important;
  border: 1px solid #ff9900 !important;
  border-radius: 5px;
  color: #ffffff;
  width: max-content;
  margin-left: auto;
}


.table-action-box {
  display: flex;
  justify-content: center;
  padding: 1% 5%;
}

.icon-action-btn,
.icon-action-btn:hover,
.icon-action-btn:active {
  background: transparent !important;
  border: none;
  border-radius: 10px;
  margin: 0%;
  padding: 0.2rem;
}
.icon-add-btn,
.icon-add-btn:active {
  background: #ff9900;
  border: 1px solid #ff9900;
  border-radius: 5px;
  color: #ffffff;
  margin-left: auto;
}
.icon-add-btn:hover {
  background: transparent;
  border: 1px solid #ff9900;
  border-radius: 5px;
  color: #231f20;
}

.icon-action-btn svg {
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 0.2rem;
}

.panel-ofcanvas {
  /* background-color: #ff9900; */
  background-image: linear-gradient(269.9deg, #ff9900 -0.78%, #fbb040 99.93%);
  clip-path: polygon(0% 0%, 0% 100%, 100% 75%, 100% 0%);
  height: 100%;
  width: 100% !important;
}

.panel-backdrop.show {
  width: 100%;
  height: 100%;
  opacity: 1 !important;
  background-color: #ffffff;
}
.panel-ofcanvas .offcanvas-header .btn-close {
  padding: 8% !important;
  margin-left: auto !important;
}
.burger-menu-wrap {
  margin-left: auto;
}
.burger-menu-wrap button {
  background-color: transparent;
  border: none;
}
.burger-menu-wrap button svg {
  height: 1.75rem;
  width: 1.75rem;
}
.table-heading-wrap {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.notifcations-popper {
  border: #fbb040 1px solid;
  font-family: "InterRegular";
  max-width: 500px !important;
  width: max-content;
  min-width: 25rem;
  height: 15rem;
  border-radius: 10px 0 0px 10px;
}
.notifcations-popper .popover-arrow::after {
  border-bottom-color: #fbb040;
}
.notifcations-popper .popover-header {
  background-color: #fbb040;
  color: #f5f5f5;
  font-size: 1.25rem;
  font-weight: 600;
  border-radius: 10px 0 0px 0px;
}
.notifcations-popper .popover-body {
  font-size: 1rem;
  color: #231f20;
  font-weight: 400;
  padding: 0.5rem 0;
}
.notifcations-popper .popover-body .container {
  /* overflow-y: scroll; */
  height: 10.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.order-popup .modal-dialog {
  padding: 0 4rem;
  max-width: 100% !important;
}

.order-popup .modal-content {
  padding: 1rem 2rem;
  width: 100%;
}
.order-popup .modal-body {
  padding: 0;
}
.order-popup .modal-header {
  padding: 0;
}
.table-action-box .action-button{
  background-color: transparent;
  border: none;
}
@media (max-width: 768px) {
  .table-action-box {
    flex-direction: column;
    align-items: center;
  }
  
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation2 {
    padding: 0.5rem 0;
  }
  .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular {
    padding: 0;
  }
  .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular p,
  .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular
    .MuiInputBase-root.MuiInputBase-colorPrimary
    .MuiSelect-select {
    font-size: 0.75rem;
  }
  .MuiBox-root .MuiButtonBase-root {
    padding-top: 0;
    padding-bottom: 0;
  }
  
  .admin-panel-section h4 {
    font-size: 1rem;
    line-height: 1.25rem;
    margin: 0;
  }
  .icon-add-btn {
    font-size: 0.75rem;
    line-height: 1rem;
  }
  .admin-panel-section h1 {
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 0;
  }

  .admin-panel-menu-col .logo-div img {
    width: 12rem;
    height: 3rem;
  }
  .admin-panel-menu-col .logo-div {
    padding: 0% 1rem;
  }
  .admin-panel-menu-col {
    padding: 1.5rem 0.1rem;
  }
  .logo-div {
    width: max-content;
    display: flex;
    align-items: center;
    padding: 0;
  }
  .logo-div img {
    width: 5rem;
    height: 1.5rem;
    margin-left: 0.5rem;
  }
  .admin-panel-content-col .header-row .header-menu {
    padding: 1rem 0;
  }
  .admin-panel-content-col .header-row .header-menu img {
    height: 2rem;
    margin-left: 1.5rem;
    width: 2rem;
  }
  .admin-panel-menu-col .logout-btn {
    margin-top: 2rem;
    padding: 0.5rem 1.5rem;
  }
  .order-popup .modal-dialog {
    padding: 0 0.5rem;
  }
}
@media (max-width: 576px) {
  .admin-panel-menu-col .logo-div {
    padding: 0%;
  }

  .admin-panel-content-col .header-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .admin-panel-content-col .header-row .header-menu {
    margin: 0%;
  }
  .admin-panel-content-col .header-row .header-menu svg {
    height: 1.5rem;
    width: 1.5rem;
  }
  .admin-panel-content-col .header-row .header-menu img {
    height: 1.5rem;
    width: 1.5rem;
  }
  .burger-menu-wrap button svg {
    height: 1.5rem;
    width: 1.5rem;
  }
  .admin-panel-menu-col .logout-btn {
    margin-top: 0.5rem 1rem;
  }
}
