.about-us-banner-background {
  width: 100%;
  height: 650px;
  position: absolute;
  background-image: linear-gradient(to right, #fdc345, #ffed88);
  clip-path: polygon(0% 0%, 0% 100%, 100% 80%, 100% 0%);
}
.about-us-banner-content {
  position: relative;
  z-index: 1;
  padding: 10% 0;
  margin: 0;
}
.about-banner-col {
  color: #000000;
  text-align: left;
  font-family: "PoppinsRegular";
  font-weight: 400;
}
.about-banner-col h1 {
  font-family: "MontserratBold";
  font-style: normal;
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 3.75rem;
  /* or 65px */
  color: #000000;
  margin: 0;
  padding: 0% 20% 0% 0%;
}
.about-banner-col h1 span {
  color: #fbb040;
}
.about-banner-col h6 {
  font-size: 1.25rem;
  line-height: 2.75rem;
  margin: 0;
  /* or 36px */
}
.about-banner-col p {
  font-family: "RobotoRegular";
  font-size: 1.5rem;
  line-height: 2.25rem;
  font-weight: 400;
  margin: 0;
  /* or 36px */
  padding: 0% 30% 0% 0%;
}

.bharee-truck {
  position: absolute;
  top: 35%;
  right: 4%;
  z-index: 100;
  width: 50%;
}
.arrow-scroll {
  color: #ffffff;
  cursor: pointer;
}

.floating-section {
  text-align: left;
}

.floating-text-col {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.floating-text-div {
  padding: 2% 4%;
  background: #ffffff;
  border: 1px solid #d9d9d9;
}
.floating-text-div.text-left {
  position: absolute;
  margin-left: 11%;
}
.floating-text-div.text-right {
  position: absolute;
  margin-right: 11%;
}

.floating-text-div p {
  font-family: "PoppinsRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 181.5%;
  /* or 29px */
  color: #626262;
}
.app-setion {
  text-align: left;
  padding: 2% 10%;
  background-color: #f5f5f5;
}
.app-setion p {
  font-family: "PoppinsRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 181.5%;
  /* or 29px */
  color: #626262;
}
.app-setion .text-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 5%;
}

.section-title-brands {
  text-align: center;
  margin-bottom: 2%;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2rem;
  font-family: "MontserratBold";
  padding: 0% 28%;
}

.section-title-we-connect {
  text-align: center;
  margin-bottom: 2%;
  font-weight: 700;
  font-size: 2.25rem;
  line-height: 2rem;
  font-family: "MontserratBold";
}

.section-title-we-connect span,
.section-title-brands span {
  color: #fdc345;
}
.floating-text-row #borderLeft {
  width: 4%;
}
.buyer-seller-section {
  background-color: #fafafa;
  padding: 2rem 0;
  text-align: left;
}
.buyer-seller-section .seller,
.buyer-seller-section .buyer {
  display: flex;
}
.buyer-seller-section  .image-col {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4rem 0;
}
/* .buyer-seller-section .seller .image-col {
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.buyer-seller-section .buyer .image-col {
  align-items: center;
  display: flex;
  align-items: center;

} */
.buyer-seller-section .buyer .image-col img {
  max-width: 50%;
}

.brand-section-title-logo-col {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.brand-section-title-text-col {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.brand-section-title-text-col h4 {
  margin: 0;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 2rem;
  font-family: "MontserratBold";
  color: #fdc345;
}
.brand-section-title-logo-col img {
  height: 1.5rem;
}
.brands-section-img-div img {
  max-width: 20%;
  margin: 0 2.5%;
}
.section-link {
  padding: 0.5rem 1rem;
  background-color: #fdc345;
  color: #1d1d1d;
  text-decoration: none;
  border: 1px solid #fdc345;
  border-radius: 3px;
  font-family: "RobotoRegular";
  font-weight: 600;
  width: max-content;
}
.section-link:hover {
  color: #fdc345;
  background-color: transparent;
  border: 1px solid #fdc345;
}
.buyer-seller-section h3 {
  font-family: "RobotoRegular";
  font-weight: 600;
  margin: 0;
}
.buyer-seller-section p {
  font-family: "RobotoRegular";
  font-weight: 400;
  color: #848faa;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.section-title-buyer {
  font-family: "MontserratBold";
  font-size: xx-large;
}
.section-title-buyer span {
  color: #fdc345;
}
.buyer-section-medium-text,
.buyer-section-small-text {
  color: #75809e;
  font-family: "RobotoRegular";
  padding-bottom: 10px;
}
.buyer-section-small-text {
  padding-bottom: 20px;
}
.steps-section {
  background-color: #fafafa;
  margin-top: 0%;
  padding-top: 2rem;
}
.section-title-steps {
  font-size: 2rem;
}
.steps-card {
  padding: 2rem;
}
.steps-card-div {
  padding: 10% 5%;
  border-radius: 30px;
  text-align: center;
  box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.15), 0 0px 12px 0 rgba(0, 0, 0, 0);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.steps-card p {
  color: #1d1d1d;
  font-family: "RobotoRegular";
  font-weight: 600;
  padding-top: 3%;
}
.steps-card img {
  width: 80%;
}
.grey-section {
  background-image: url("../../assets/images/GreyCurve.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 59px;
}
.before-after-section {
  display: flex;
}

.accordion-item {
  border-top: 0px !important;
  border-left: 0px !important;
  border-right: 0px !important;
  border-bottom: 2px solid #d4d4d4 !important;
}
.accordion-item button {
  font-family: "RobotoRegular";
  font-weight: 600;
  color: #1d1d1d;
}
.accordion-button:not(.collapsed) {
  color: #1d1d1d !important;
  background-color: #ffffff !important;
}
.accordion-button:not(.collapsed)::after {
  background-image: url("../../assets/images/MinusSign.png") !important;
}
.accordion-button:focus {
  box-shadow: none !important;
}
.accordion-button::after {
  background-image: url("../../assets/images/PlusSign.png") !important;
}
.accordion-body {
  font-family: "RobotoRegular";
  font-weight: 400;
  color: #1d1d1d;
}

.we-connect-section {
  padding-top: 12rem;
}
.we-connect-section-img {
  padding: 1rem;
}
@media (max-width: 1024px) {
  .we-connect-section {
    padding-top: 5rem;
  }
  .we-connect-section-img {
    padding: 1rem 6rem;
  }
  .brand-section-title-logo-col {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .brand-section-title-text-col {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .brand-section-title-text-col h4 {
    margin: 0;
    font-weight: 700;
    font-size: 1.75rem;
    line-height: 2rem;
    font-family: "MontserratBold";
    color: #fdc345;
    padding: 5% 0;
  }
  .brand-section-title-logo-col img {
    height: 3.5rem;
  }
  .section-title-we-connect {
    font-size: 1.75rem;
    line-height: 1.75rem;
  }
  .buyer-seller-section .button-col {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: 1rem 0;
  }
  .about-us-banner-background {
    width: 100%;
    height: 550px;
  }
  .about-banner-col h1 {
    font-size: 2.5rem;
    line-height: 2.25rem;
    margin-bottom: 5%;
  }
  .about-banner-col p {
    font-size: 1.25rem;
    line-height: 1.25rem;
    margin-bottom: 5%;
  }
  .about-us-banner-content {
    padding: 15% 0;
  }
  .buyer-seller-section .image-col{
    padding: 2rem 0;
  }
}
@media (max-width: 768px) {
  .we-connect-section {
    padding-top: 0%;
  }


  .section-title-we-connect {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .about-us-banner-content.mobile .about-banner-col {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 10%;
  }
  .about-us-banner-background {
    width: 100%;
    height: 650px;
  }
  .about-banner-col h1 {
    font-size: 1.25rem;
    line-height: 1.5rem;
    text-align: center;
    padding: 2% 0%;
    margin: 5% 0;
  }
  .about-banner-col p {
    font-size: 0.75rem;
    line-height: 0.75rem;
    text-align: center;
    padding: 2% 0%;
    margin: 5% 0;
  }
  .about-us-banner-content {
    text-align: center;
    padding: 20% 0;
  }
  .brand-section-title-text-col h4 {
    font-size: 1.25rem;
    line-height: 1.75rem;
    padding: 5% 0;
  }
  .brand-section-title-logo-col img {
    height: 2.5rem;
  }
  .buyer-seller-section .image-col{
    padding: 1rem;
  }
}
@media (max-width: 576px) {
  .about-us-banner-content.mobile {
    margin-bottom: 10%;
  }
  .about-us-banner-background {
    width: 100%;
    height: 525px;
  }
}
