.brands-main-section {
  background-color: #f5f5f5;
}
.brands-banner-main-div {
  padding: 3%;
  color: #000000;
  background-color: #f5f5f5;
  background-image: url("../../assets/images/Brands/BrandHomeBanner.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.brands-banner-main-div h2 {
  margin: 0;
  font-weight: 800;
  font-size: 1.6rem;
  line-height: 2rem;
  font-family: "MontserratExtraBold";
}
.brands-banner-main-div p a {
  /* margin: 0; */
  color: #000000;
  text-decoration: none;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1rem;
  font-family: "MontserratRegular";
}

.brands-div {
  margin: 0;
  padding: 5% 0;
}
.filter-container {
  padding: 3rem 0rem;
}
.filter-section {
  min-height: 500px;
  height: max-content;
  padding: 0 1%;
  text-align: left;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-shadow: 2px 4px 10px -5px rgba(0, 0, 0, 0.25);
  border-radius: 0.6rem;
}
.filter-section hr {
  margin: 1% 5% 5% 5%;
}
.filter-heading {
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.8rem;
  color: #000000;
  max-width: max-content;
  margin: 0;
  padding: 5% 9%;
}
.filter-options input[type="checkbox"] {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
/* .filter-options label span {
  border: 5px solid #d9d9d9;
} */
.filter-options label {
  display: flex;
  align-items: center;
}
.filter-options p {
  margin: 0;
  padding: 0 0.5rem;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 2.5rem;
  color: #626262;
}
.filter-options-herading {
  font-weight: 700;
  font-size: 1.1rem;
  line-height: 1.4rem;
  color: #000000;
}
.filter-options .checkbox {
  display: inline-block;
  height: 20px;
  width: 20px;
  background: #fff;
  margin-right: 4px;
  border: 3px solid #d9d9d9;
}

.filter-options .checkbox--active {
  background-color: #000000;
  border: 5px solid #d9d9d9;
}

.filter-options select {
  border: 1px solid #626262;
  border-radius: 1px;
}
.filter-options select:focus {
  border: 1px solid #626262;
  box-shadow: none;
}
.brand-card-col {
  margin: 1rem 0;
}
.brand-card-col a {
  text-decoration: none;
}
.brand-section {
  padding: 1%;
  padding-top: 0;
}
.brand-section .row {
  padding: 1% 3%;
  padding-top: 0;
}
.brand-section .selector {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0% 1%;
  padding-top: 0;
}
.brand-section .selector .selected-option p {
  margin: 0;
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 1.25rem;
  color: #000000;
  margin-right: 1%;
}
.brand-section .selector .selected-option {
  display: flex;
  align-items: center;
  width: 100%;
}
.brand-section .selector .selected-option button {
  border: 1px solid #626262;
  background-color: transparent;
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 1rem;
  margin: 0% 1%;
  color: #626262;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.brand-section .selector .selected-option button svg {
  padding-left: 3%;
}
.brand-section .selector .selected-option button span {
  width: max-content;
}
.brand-section .selector .dropdown {
  margin-right: 3%;
}
.brand-section .selector .dropdown button {
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  color: #626262;
  padding: 5% 10%;
}
.brand-section .selector .dropdown svg {
  padding: 1%;
  margin-left: 3%;
}
.filter-icon {
  width: fit-content;
  background-color: transparent !important;
  border: none !important;
}
.filter-rating-options {
  display: flex;
  flex-direction: column;
}
.filter-offcanvas {
  background-image: linear-gradient(to right, #fdc345, #ffed88);
  clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 100% 0%);
  height: 100%;
  width: 100% !important;
}

.filter-offcanvas.show {
  width: 100%;
  height: 100%;
  opacity: 1 !important;
  background-color: #ffffff;
}
.filter-offcanvas .offcanvas-header .btn-close {
  padding: 8% !important;
  margin-left: auto !important;
}

.pagination-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.pagination-count-view {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}
.pagination-count-view p {
  font-family: "InterRegular";
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0em;
  margin: 0;
}
.pagination-action-warp {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 1.5rem;
}
.pagination-action-warp p {
  font-family: "RobotoRegular";
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.2rem;
  letter-spacing: 0em;
  padding: 0.6rem 0.85rem;
  margin: 0 0.5rem;
  border: 1px solid #817f7f;
  border-radius: 50%;
}
.pagination-action-warp p.active {
  font-family: "RobotoRegular";
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.2rem;
  letter-spacing: 0em;
  padding: 0.6rem 0.85rem;
  margin: 0 0.5rem;
  background-color: #fbb040;
  border: 1px solid #fbb040;
  border-radius: 50%;
}
.pagination-btn {
  color: #626262;
  padding: 0.7rem;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  border: 1px solid #817f7f;
  border-radius: 50%;
  margin-left: 0.2rem;
}

.pagination-btn:hover {
  color: #626262;
  background-color: transparent;
  display: flex;
  align-items: center;
  border: 1px solid #817f7f;
  border-radius: 50%;
}
.pagination-btn svg {
  height: 1rem;
  width: 1rem;
  fill: #000000;
}
.pagination-btn.filp svg {
  rotate: 180deg;
}
@media (max-width: 768px) {
  .pagination-action-warp {
    padding: 1.5rem 0;
  }
  .filter-container {
    padding: 0rem;
  }
  .filter-offcanvas {
    padding: 0% !important;
    width: 100% !important;
    background-color: transparent !important;
  }
  .filter-offcanvas .offcanvas-header .btn-close {
    padding: 2% 4% !important;
    margin-left: auto !important;
  }
  .filter-backdrop.show {
    opacity: 1 !important;
    background-color: #ffffff;
  }
  .filter-ofcanvas-body {
    box-shadow: 5px 19px 17px rgba(0, 0, 0, 0.07);
    padding: 0% 1rem;
    padding-bottom: 5rem;
    margin: 0%;
  }
  /* .filter-section {
    margin-bottom: 10rem;
  } */
  .brands-div {
    padding-top: 10%;
  }
  .brand-section .selector .dropdown {
    margin-right: 7.5%;
  }
  .brand-section .selector {
    padding: 5% 1%;
    align-items: center;
  }
  .brand-card-col {
    padding: 0% 1% !important;
  }
}
@media (max-width: 576px) {
  .brands-div {
    padding-top: 25%;
  }
}
