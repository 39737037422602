.quote-section {
  background-color: #fdc345;
  padding: 1rem 8rem;
  width: 100%;
}
.quote-section .text-quote-col {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "PoppinsRegular";
  font-weight: 400;
  font-size: 2.25rem;
  line-height: 2.5rem;
}
.quote-section .left-quote-col {
  display: flex;
  justify-content: flex-end;
}
.quote-section .right-quote-col {
  display: flex;
  justify-content: flex-start;
}

@media (max-width: 1024px) {
  .quote-section .left-quote-col {
    display: flex;
    justify-content: flex-start;
  }
  .quote-section .right-quote-col {
    display: flex;
    justify-content: flex-end;
  }
}
@media (max-width: 768px) {
  .quote-section{
    padding: 0.5rem 0.75rem;
  }
  .quote-section .left-quote-col img,
  .quote-section .right-quote-col img {
    height: 3rem;
    padding: 0.3rem 0rem;
  }
  .quote-section .text-quote-col {
    font-size: 1.75rem;
    line-height: 2rem;
    padding: 0.75rem 0;
    
  }
  .quote-section .text-quote-col p {
   margin: 0;
  }
}
@media (max-width: 576px) {
}
