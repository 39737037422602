.seller-banner-main-div {
  padding: 4.5% 5%;
  color: #000000;
  background-color: #f5f5f5;
  background-image: url("../../assets/images/seller-page-banner.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.seller-banner-main-div .seller-banner-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  padding-left: 8%;
}
.seller-banner-main-div .seller-banner-text h1 {
  font-family: "MontserratExtraBold";
  font-style: normal;
  font-size: 2rem;
  line-height: 2.5rem;
  text-transform: uppercase;
  color: #000000;
}
.seller-banner-main-div .seller-banner-text p {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #231f20;
}
.seller-banner-main-div .seller-banner-text button {
  background: #000000;
  border-radius: 38px;
  border: none;
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.9rem;
  text-transform: uppercase;
  padding: 0.5rem 1.5rem;
  color: #d9d9d9;
  margin-top: 2%;
}
.seller-page-delivery {
  padding: 2% 1%;
  background-color: #f5f5f5;
}
.seller-page-delivery-right {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 9% 0 1% 0;
}

.seller-page-delivery-right h1 {
  font-family: "MontserratMedium";
  font-style: normal;
  font-weight: 500;
  font-size: 1.7rem;
  line-height: 2.5rem;
  text-transform: uppercase;
  margin-bottom: 2%;
  color: #000000;
}
.sevices-container {
  padding: 2% 5%;
  text-align: left;
}
.sevices-container .section-title {
  text-align: initial;
  margin-bottom: 2%;
  font-weight: 800;
  font-size: 1.5rem;
  line-height: 2rem;
  font-family: "MontserratExtraBold";
  padding: 0% 1%;
}
.sevices-container p {
  font-family: "PoppinsRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.75rem;
  color: #626262;
  padding: 0% 1%;
}

.sevices-description-col {
  background-color: #faf7f5;
  text-align: left;
}
.sevices-description-col p {
  font-family: "PoppinsRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #626262;
  padding: 7%;
  padding-right: 20%;
}
.sevices-description-col p strong {
  font-weight: bold;
  color: #000000;
}

.services-icons {
  padding: 5% 20%;
}

.services-icons .services-icons-col {
  font-family: "MontserratBold";
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.25rem;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
}
.services-icons .services-icons-col p {
  margin-top: 10%;
}

.services-points {
  text-align: left;
}
.services-points .services-points-col {
  padding: 4%;
}
.services-points .services-points-col.left {
  padding-left: 7%;
  background-color: #f5f5f5;
}
.services-points .services-points-col.right {
  padding-right: 9%;
  padding-left: 7%;

  background-color: #d8f1ff;
}
.services-points .services-points-col h6 {
  font-family: "MontserratBold";
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.25rem;
  text-transform: uppercase;
  color: #007bdb;
}
.services-points .services-points-col ul {
  padding: 0;
  margin: 0;
}
.services-points .services-points-col ul li {
  display: flex;
  align-items: flex-start;
  margin: 2% 0%;
}
.services-points .services-points-col ul li p {
  margin: 0;
  font-family: "PoppinsRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-left: 1%;
}
.services-points .services-points-col ul li svg {
  padding: 3px;
}

@media (max-width: 768px) {
  .services-icons {
    padding: 5% 1%;
  }
  .services-icons .services-icons-col p {
    font-size: 0.5rem;
  }
  
}
@media (max-width: 576px) {
}
