.logo_input_col {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .modal-custom-class .col-md-6 {
    padding: 0;
  }
}
