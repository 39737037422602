.url-images-section {
  /* border: 5px #fbb040 dotted; */
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23FBB040FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");

  font-family: "InterRegular";
  color: #626262;
  font-size: 1.5rem;
  font-weight: 400;
}

/* .drag-drop {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 2.5rem 1.5rem;
}
.action-buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.drag-drop-btn {
  padding: 0.5rem !important;
  width: max-content !important;
} */

.uploded-url-images-div {
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.uploded-url-images-div .img-container {
  width: 10rem;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  margin: 0.5rem;
  border: 1px #fbb040 solid;
  position: relative;
}
.uploded-url-images-div img {
  width: 100%;
  height: auto;
}
.uploded-url-images-div .badge {
  background-color: transparent !important;
  border-radius: 50%;
  border: 1px solid red;
  padding: 0.2rem 0.4rem;
  position: absolute;
  z-index: 1;
  right: -12px;
  top: -12px;
}
.deleted-url-images-div {
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.deleted-url-images-div .img-container {
  width: 10rem;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  margin: 0.5rem;
  border: 1px red solid;
  position: relative;
  /* background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23FBB040FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e"); */
}
.deleted-url-images-div img {
  width: 100%;
  height: auto;
}
.deleted-url-images-div .badge {
  background-color: transparent !important;
  border-radius: 50%;
  border: 1px solid green;
  padding: 0.2rem 0.4rem;
  position: absolute;
  z-index: 1;
  right: -12px;
  top: -12px;
  color: green;
  font-size: 1.25rem;
}
.uploded-url-file-div {
  padding-top: 1.5rem;
  padding-bottom: 0.5rem;
}
@media (max-width: 768px) {
  .uploded-url-images-div .img-container,
  .deleted-url-images-div .img-container {
    width: 5rem;
    padding: 0.2rem;
    margin: 0.2rem;
  }
  .uploded-url-images-div,
  .deleted-url-images-div {
    padding: 0.1rem;
  }
  .url-images-section h6 {
    text-align: center;
  }
  .uploded-url-file-div {
    padding-top: 0.75rem;
  }
}
