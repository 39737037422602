@media (max-width: 768px) {
 
  .bhree-admin-service-tax-list .title-button-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .bhree-admin-service-tax-list .title-button-row .view-switch-btn {
    font-size: 0.75rem;
  }
}
