.buyer-section {
  text-align: left;
}
.section-title-steps {
  text-align: center;
  margin-bottom: 2%;
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 3rem;
  font-family: "MontserratBold";
  padding: 0% 28%;
}
.section-title-steps span {
  color: #fdc345;
}
.about-us-buyer {
  background-image: url("../../assets//images/greyBackgroud.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 138px;
}

.buyer-section .section-title-buyer {
  font-family: "MontserratBold";
  font-size: xx-large;
}
.section-title-buyer img {
  vertical-align: baseline;
}
.buyer-text-col {
  padding: 5% 0;
}
.buyer-section-img {
  max-width: 100%;
}
.buyer-section-medium-text h5 {
  line-height: 2rem;
}
.buyer-section-small-text h6 {
  line-height: 1.5rem;
}
.before-after-section-right,
.before-after-section-left {
  padding: 3% 5% !important;
  display: flex;
  flex-direction: column;
}
.before-after-section-right {
  background-color: rgba(253, 195, 69, 0.21);
  border-radius: 68px 0px 0px 68px;
}
.before-after-info {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.before-after-info {
  color: #75809e;
  font-family: "RobotoRegular";
  font-weight: 400;
  /* line-height: 19px; */
  text-align: left;
}
.before-after-info img,
.before-after-info p {
  width: 50%;
}
.before-after-heading p {
  text-align: left;
  color: #1d1d1d;
  font-size: 35px;
  font-weight: bold;
}
.faq-line {
  border: 3.5px solid #fdc345;
  width: 75%;
  margin-bottom: 3%;
}
.faq-section-left,
.faq-section-right {
  text-align: left;
}
.faq-section-left {
  display: flex;
  flex-direction: column;
}
.faq-bigtext {
  color: #1d1d1d;
  font-size: 2.5rem;
  font-weight: 600;
}
.faq-smalltext {
  color: #75809e;
  font-size: 25px;
  font-family: "RobotoRegular";
  font-weight: 400;
}
.buyer-text-row {
  padding: 3rem;
}
@media (max-width: 1024px) {
  .faq-bigtext {
    font-size: 2rem;
  }
  .faq-smalltext {
    font-size: 1rem;
  }
  .before-after-info img {
    margin: 0 auto;
  }
  .before-after-info img,
  .before-after-info p {
    width: 100%;
  }

  .section-title-steps {
    font-size: 2rem;
    line-height: 2.5rem;
  }
}
@media (max-width: 992px) {
  .faq-line {
    width: 100%;
  }
  .before-after-heading p {
    text-align: center;
    color: #1d1d1d;
    font-size: 35px;
    font-weight: bold;
  }
  .buyer-text-col {
    padding: 10% 0;
  }
  .buyer-text-row {
    padding: 1rem;
    text-align: center;
  }
  .before-after-section-right {
    border-radius: 68px 68px 0px 0px;
  }
}
@media (max-width: 768px) {
  .about-us-buyer {
    height: 100px;
  }
  .faq-section-left,
  .faq-section-right {
    padding: 0% 1rem;
  }
  .buyer-section .section-title-buyer img {
    height: 1.5rem;
    font-size: 1.5rem;
  }
  .buyer-section .section-title-buyer {
    font-size: 1.5rem;
  }
  .section-title-buyer img {
    height: 1.5rem;
    font-size: 1.5rem;
  }
  .buyer-section-medium-text h5 {
    line-height: 1.25rem;
    line-height: 1.5rem;
  }
  .buyer-section-small-text h6 {
    line-height: 0.75rem;
    line-height: 1rem;
  }
  .section-title-steps {
    font-size: 1.5rem;
    line-height: 2rem;
    padding: 0%;
    text-align: left;
    text-align: center;
  }
}
@media (max-width: 576px) {
}
