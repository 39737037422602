.order-main-section {
  background-color: #ffffff;
}
.order-banner-main-div {
  font-family: "MontserratRegular";
  padding: 4%;
  color: #ffffff;
  background-color: #f5f5f5;
  background-image: url("../../assets/images/Cart/CartBanner.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.order-banner-main-div h2 {
  margin: 0;
  font-weight: 800;
  font-size: 1.6rem;
  line-height: 2rem;
}
.order-banner-main-div p {
  margin: 0;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1rem;
}
.order-banner-main-div p a {
  color: #ffffff;

  text-decoration: none;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1rem;
}
.order-banner-main-div p svg {
  margin: 0% 5px;
}
.order-section-title-div {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.order-section-title-div h4 {
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: #000000;
}
.order-section-title-div p {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  color: #000000;
}

/*  progresss barr */
.order-status-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.step {
  text-align: center;
}

.active .icon {
  /* Style for active icon */
  color: green;
}

.icon {
  /* Style for default icon */
  color: gray;
}

.status {
  margin-top: 8px;
}

.order-info p {
  font-size: 1rem;
  font-family: "InterRegular";
}
.order-info p span {
  font-weight: 600;
}
.left-col {
  text-align: end;
}
@media (min-width: 1400px) {
  /* .cart-order-summary-section {
    padding: 0% 8%;
    padding-top: 4%;
  } */
}
@media (max-width: 1024px) {
  .cart-order-summary-section {
    padding: 0% 4rem;
    padding-top: 2rem;
  }
}
@media (max-width: 768px) {
  .cart-order-summary-section {
    padding: 1rem;
    padding-top: 1rem;
  }
  .order-summery-card .description-col .summary-card-title svg {
    margin-right: 0%;
  }
  .summary-card-qnt .summary-card-more-option {
    width: max-content;
  }
  .order-section-title-div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
  .order-section-title-div #borderLeft {
    width: 30%;
  }
  .left-col {
    text-align: start;
  }
}
@media (max-width: 576px) {
}
