.drag-drop-section {
  /* border: 5px #fbb040 dotted; */
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23FBB040FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");

  font-family: "InterRegular";
  color: #626262;
  font-size: 1.5rem;
  font-weight: 400;
}

.drag-drop {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 2.5rem 1.5rem;
}
 .drag-drop-section .action-buttons {
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
}
.drag-drop-btn {
  padding: 0.5rem !important;
  width: max-content !important;
}

.uploded-images-div {
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.uploded-images-div .img-container {
  width: 10rem;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  margin: 0.5rem;
  border: 1px #fbb040 solid;
  /* background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23FBB040FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e"); */
}
.uploded-images-div img {
  width: 100%;
  height: auto;
}
.uploded-file-div {
  padding-top: 1.5rem;
}

@media (max-width: 768px) {
  .uploded-images-div .img-container {
    width: 5rem;
    padding: 0.2rem;
    margin: 0.2rem;
  }
  .uploded-images-div {
    padding: 0.1rem;
  }
  .drag-drop {
    padding: 1rem 0.5rem;
  }
  .drag-drop-section h6 {
    text-align: center;
  }
  .uploded-file-div {
    padding-top: 0.75rem;
  }
}
