.barand-card-wrap {
  text-decoration: none;
}
.brand-card-div {
  background-color: #ffffff;
  border-radius: 10px;
  height: 100%;
}

.brand-card-div .band-title {
  position: absolute;
  padding: 1% 0%;
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 1rem;
  letter-spacing: 0.1em;
  color: #817f7f;
}

.brand-card-div .brand-card-rating {
  display: flex;
  align-items: baseline;
  padding: 0%;
  margin-bottom: 1%;
  justify-content: space-between;
}
.brand-card-div .brand-card-rating p {
  margin: 0;
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 1rem;
}
.brand-card-div .brand-card-rating span {
  color: #000000;
}
.brand-card-div .brand-card-rating a {
  font-weight: 800;
  font-size: 0.75rem;
  line-height: 1.25rem;
  color: #fbb040 !important;
}

.brand-card-img {
  padding: 5% 0;
  height: 200px;
}

.brand-card-image-heading {
  display: flex;
  position: absolute;
  justify-content: space-between;
  padding: 2% 0;
  align-items: center;
  width: 100%;
}
.brand-card-image-heading p {
  margin: 0;
  padding-left: 5%;
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 1rem;
  text-transform: uppercase;
  color: #817f7f;
}
.brand-card-body {
  padding: 5%;
  text-align: left;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.brand-card-body h4 {
  margin: 0;
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 1.3rem;
  color: #000000;
}
.brand-card-body p {
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1rem;
  color: #817f7f;
  margin: 0;
}

.rating-star-container {
  display: flex;
}
.brand-card-MOQ {
  margin: 1% 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.brand-card-MOQ span {
  color: #000000;
}
.brand-card-cart {
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.brand-card-cart h3 span {
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1rem;
  color: #000000;
}
.brand-card-cart h3 p {
  font-weight: 800;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #fbb040 !important;
}
.brand-card-cart h3 {
  margin: 0;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 1rem;
  color: #000000;
}
.brand-card-cart button {
  background-color: #00b3f7;
  border-radius: 5px;
  border: 1px solid #00b3f7;
  font-size: 0.9rem;
  line-height: 1rem;
  margin-left: auto;
  color: #ffffff;
}
.brand-card-cart button:hover {
  background-color: transparent;
  border-color: #00b3f7;
  color: #00b3f7;
}
.brand-card-cart button:hover svg g path {
  fill: #00b3f7 !important;
}

.brand-card-big-img {
  height: 100%;
  max-width: 100%;
}
.moq-row {
  display: flex;
  justify-content: flex-start;
}

.moq-btn {
  width: max-content;
  background-color: #00b3f7;
  border-radius: 5px;
  border: 1px solid #00b3f7;
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 600;
  color: #ffffff;
  margin-right: 1rem;
}
.moq-btn:hover {
  background-color: transparent;
  border-color: #00b3f7;
  color: #00b3f7 !important;
}
.moq-row :last-child {
  margin-right: 0;
}
.card-popper {
  border: #00b3f7 1px solid;
  font-family: "InterRegular";
  width: max-content;
  max-width: 500px !important;
  min-width: max-content;
}
.card-popper .popover-arrow::after {
  border-bottom-color: #00b3f7;
}
.card-popper .popover-header {
  background-color: #00b3f7;
  color: #f5f5f5;
  font-size: 1rem;
  font-weight: 600;
}
.card-popper .popover-body {
  padding: 0.1rem 0;
}
.card-popper .popover-body .container {
  /* overflow-y: scroll; */
  padding: 1rem;
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: center; */
  /* align-items: center; */
}

.card-popper .popover-body .container .offer-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-bottom: 0.1rem;
  font-family: "InterRegular";

}

.card-popper .popover-body .container .offer-row .offer-heading {
  font-size: 1rem;
  font-weight: 600;
}
.card-popper .popover-body .container .moq-row {
  /* overflow-y: scroll; */
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: max-content;
  width: 100%;
  padding: 0 1rem;
}

@media (max-width: 768px) {
  
  .moq-btn {
    font-size: 0.75rem;
    line-height: 0.9rem;
    font-weight: 600;
    color: #ffffff;
    margin-right: 0.75rem;
  }
  .brand-card-cart h3 p {
    font-weight: 800;
    font-size: 0.6rem;
    line-height: 0.9rem;
    color: #fbb040 !important;
  }
  .brand-card-big-img {
    height: 100%;
    max-width: 100%;
  }
  .brand-card-img {
    height: 150px;
  }
  .brand-card-body h4 {
    margin: 0.25rem 0;
    font-size: 8.0381px;
    line-height: 0.75rem;
  }
  .brand-card-body p {
    font-size: 7.03333px;
    line-height: 8px;
  }
  .brand-card-div .brand-card-rating p {
    font-size: 7.03333px;
    line-height: 8px;
  }
  .brand-card-cart h3 {
    font-size: 10.0476px;
    line-height: 12px;
  }
  .brand-card-cart h3 span {
    font-size: 7.03333px;
    line-height: 8px;
  }
  .brand-card-cart button {
    font-size: 7.03333px;
    line-height: 8px;
    padding: 2% 6%;
  }
  .brand-card-cart button svg {
    padding: 15%;
  }
  .brand-card-div .brand-card-rating {
    justify-content: flex-start;
    padding: 0;
  }
  .brand-card-div .brand-card-rating a{
    margin-left: auto;
    font-size: 0.5rem;
    line-height: 1.5rem;
  }
}
@media (max-widh: 676px) {
  .brand-card-img {
    height: 100px;
  }
}
