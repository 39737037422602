.account-main-section {
  background-color: #f8f8f8;
}
.account-banner-main-div {
  padding: 4%;
  color: #626262;
  background-color: #f1f8ff;
  background-repeat: no-repeat;
  background-size: cover;
}
.account-banner-main-div h2 {
  margin: 0;
  font-weight: 800;
  font-size: 1.6rem;
  line-height: 2rem;
}
.account-banner-main-div p {
  margin: 0;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1rem;
}

.account-side-menu {
  text-align: left;
  background: transparent;
  border: 1px solid #d9d9d9;
  box-shadow: 2px 4px 10px -5px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.account-side-menu-option {
  border-bottom: 1px solid #d9d9d9;
}
.account-side-menu-option .heading-menu {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 0.8rem;
  line-height: 1rem;
  letter-spacing: 0.1em;
  color: #231f20;
  padding: 8%;
  padding-bottom: 0%;
  background-color: #ffffff;
}
.account-side-menu-option .heading-menu h5 {
  font-weight: 700;
  font-size: 0.8rem;
  line-height: 1rem;
  letter-spacing: 0.1em;
  color: #231f20;
}
.account-side-menu-option .heading-menu svg {
  padding: 1.5%;
}
.account-side-menu-option .heading-menu.active {
  background: linear-gradient(269.9deg, #ff9900 -0.78%, #fbb040 99.93%);
  padding-bottom: 8%;
}
.account-side-menu-option .submenu {
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1rem;
  letter-spacing: 0.1em;
  color: #626262;
  padding: 5% 20%;
  background-color: #ffffff;
}
.account-side-menu-option button {
  width: 100%;
  background-color: transparent;
  text-align: left;
  border: none;
  padding: 5% 15%;
  font-weight: 700;
  font-size: 0.85rem;
  line-height: 1.1rem;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #231f20;
}
.account-side-menu-option button:hover {
  width: 100%;
  background-color: transparent;
  text-align: left;
  border: none;
  padding: 5% 15%;
  font-weight: 700;
  font-size: 0.85rem;
  line-height: 1.1rem;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #231f20;
}

.my-order-headingbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.my-order-headingbox h4 {
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: #626262;
}
.search-and-dropdown-box {
  display: flex;
  align-items: center;
}
.search-and-dropdown-box svg {
  margin: 0 1rem;
}
.search-and-dropdown-box select {
  background-color: transparent;
  display: flex;
  align-items: center;
  border: none;
}

/* fasdf */

.order-summary-section {
  padding-top: 5%;
}
/* .order-summary-section .order-section-title-div {
  display: flex;
  justify-content: space-between;
}
.order-section-title-div h4 {
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: #000000;
}
.order-section-title-div p {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  color: #000000;
} */
.order-summery-card-my-account {
  padding: 1%;
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
}
.order-summery-card-my-account .image-col {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 9rem;
}
.order-summery-card-my-account .image-col img {
  max-height: 100%;
  max-width: 100%;
}
.order-summery-card-my-account .description-col {
  text-align: start;
  padding: 0;
  padding-top: 2%;
}
.order-summery-card-my-account .description-col .summary-card-title h5 {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  color: #626262;
}

.summary-card-details .my-acccount-summary-card-qnt {
  display: flex;
  justify-content: space-between;
}

.my-acccount-summary-card-qnt .my-acccount-summary-card-item p {
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1rem;
  color: #817f7f;
}
.my-acccount-summary-card-qnt .my-acccount-summary-card-item h6 {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #626262;
  margin: 0;
}

.invoice-btn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  padding: 2% 2% 3% 2%;
}
.invoice-btn p {
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 1rem;
  text-align: right;
  width: max-content;
  color: #626262;
  margin: 0;
}
.invoice-btn button {
  background-color: #f5f5f5;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  font-size: 0.8rem;
  line-height: 1rem;
  text-align: center;
  letter-spacing: 0.05em;
  color: #231f20;
  padding: 5% 15%;
  /* display: flex;
  align-items: center; */
  text-align: end;
}
.invoice-btn svg {
  /* margin: 0 2%; */
  padding: 2%;
}
.invoice-btn button:hover {
  background-color: #f5f5f5;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  font-size: 0.8rem;
  line-height: 1rem;
  text-align: center;
  letter-spacing: 0.05em;
  color: #231f20;
  padding: 5% 15%;
}

.pending-order-headingbox h4 {
  padding-top: 2%;
  font-weight: 600;
  font-size: 0.9rem;
  line-height: 1.25rem;
  color: #626262;
  text-align: start;
}

.pendiing-order-btn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  padding: 2% 2% 3% 2%;
}
.pendiing-order-btn p {
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 1rem;
  text-align: right;
  width: max-content;
  color: #626262;
  margin: 0;
}
.pendiing-order-btn button {
  background-color: #ff4f4f;
  border: none;
  padding: 5% 15%;
  border-radius: 5px;
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 1rem;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #ffffff;
}
.pendiing-order-btn button:hover {
  background-color: #ff4f4f;
  border: none;
  padding: 5% 15%;
  border-radius: 5px;
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 1rem;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #ffffff;
}
.pendiing-order-btn span {
  margin-top: auto;
  margin-bottom: 5px;
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 1rem;
  text-align: right;

  color: #ff4f4f;
}

.bussiness-informatio {
  text-align: left;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 3% 5%;
}
.bussiness-informatio h5 {
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.5rem;

  color: #231f20;
}
.bussiness-informatio .form-col.left {
  padding-left: 0;
  padding-right: 5%;
}
.bussiness-informatio .form-col.right {
  padding-right: 0;
  padding-left: 5%;
}
.bussiness-informatio input {
  border: 1px solid #d9d9d9 !important;
  border-radius: 0;
}
.bussiness-informatio label {
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1rem;
  color: #626262;
}
.form-bnt-div {
  display: flex;
  align-items: center;
  align-items: flex-end;
  padding-top: 20%;
}

.form-bnt-div button {
  border-radius: 5px;
  margin-left: 5%;
  text-transform: uppercase;
  font-size: 0.9rem;
  line-height: 1rem;
  letter-spacing: 0.1em;
  color: #231f20;
  font-weight: 600;
  padding: 3% 10%;
}
.form-bnt-div .reste {
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
}
.form-bnt-div .submit {
  background-color: #ff9900;
  border: 1px solid #d9d9d9;
}

.mannage-address {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 3% 5%;
  text-align: left;
}
.mannage-address .heading-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mannage-address .heading-div h5 {
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: #231f20;
}
.mannage-address .heading-div p {
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 1rem;
  text-align: right;
  color: #00b3f7;
}
.mannage-address .right {
  display: flex;
  align-items: flex-end;
  padding-top: 2%;
  /* align-items: center; */
}
.mannage-address .left {
  padding-left: 0;
}
.mannage-address .left h6 {
  font-weight: 700;
  font-size: 0.9rem;
  line-height: 1rem;
  letter-spacing: 0.1em;
  color: #000000;
}
.mannage-address .left p {
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 1rem;
  color: #231f20;
}
.mannage-address .left p span {
  color: #807f7f;
}
.save-card-secvtion {
  text-align: left;

  background-color: #ffffff;
  border-radius: 10px;
  padding: 3% 5%;
  text-align: left;
}
.save-card-secvtion p {
  padding: 0;
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 1rem;
  color: #000000;
}
.save-card-secvtion .left {
  padding-left: 0;
  padding-right: 4%;
}
.save-card-secvtion .right {
  padding-right: 0;
  padding-left: 4%;
}

.save-card-secvtion .heading-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.save-card-secvtion .heading-div h5 {
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: #231f20;
}
.save-card-secvtion .heading-div p {
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 1rem;
  text-align: right;
  color: #00b3f7;
}

.cred-details-box {
  padding: 2% 3%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #d9d9d9;
}

.cred-details-box h6 {
  font-size: 0.9rem;
  line-height: 1rem;
  letter-spacing: 0.1em;
  color: #000000;
}

.account-profile-side-menu-icon {
  padding: 0% 5%;
}
.account-profile-side-menu-icon button {
  background-color: transparent;
  border: none;
  padding: 0;
}
.account-profile-side-menu-icon button {
  background-color: transparent;
  border: none;
  padding: 0;
}
.pointer {
  cursor: pointer;
}
.order-link{
  text-decoration: none;
}
@media (max-width: 768px) {
  .order-summery-card-my-account .description-col .summary-card-title {
    display: flex !important;
  }
  .order-summery-card-my-account .description-col .summary-card-title p {
    font-size: 0.5rem;
    line-height: 0.8rem;
    margin: 0;
  }
  .order-summery-card-my-account .pendiing-order-btn button {
    padding: 5% 15%;
    font-size: 0.45rem;
    line-height: 0.7rem;
    border-radius: 2px;
  }
  .order-summery-card-my-account .pendiing-order-btn p {
    font-size: 0.45rem;
    line-height: 0.7rem;
  }
  .order-summery-card-my-account .pendiing-order-btn span {
    font-size: 0.45rem;
    line-height: 0.7rem;
  }
  .order-summery-card-my-account .invoice-btn button {
    border-radius: 5px;
    font-size: 0.4rem;
    line-height: 0.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    padding: 1% 5%;
    width: 100%;
  }
  .order-summery-card-my-account .invoice-btn button svg {
    padding: 1%;
  }
  .order-summery-card-my-account .image-col img {
    width: 100%;
    height: auto;
    /* padding-top: 2% ; */
  }
  .order-summery-card-my-account .description-col .summary-card-title h5 {
    font-size: 0.5rem;
    line-height: 0.7rem;
  }
  .account-profile-side-menu-icon {
    padding: 0;
  }
  .account-profile-side-menu-icon {
    display: flex !important;
  }
  .bussiness-informatio .form-col.left {
    padding: 0 2%;
  }
  .bussiness-informatio .form-col.right {
    padding: 0 2%;
  }
  .save-card-secvtion .form-col.left {
    padding: 0;
  }
  .save-card-secvtion .form-col.right {
    padding: 0;
  }
  /* .order-summery-card-my-account .description-col {
    padding: 5%;
       
  } */

  .invoice-btn,
  .pendiing-order-btn {
    padding: 2% 1% 5% 1% !important;
  }
  .invoice-btn p,
  .pendiing-order-btn span {
    margin-bottom: 5% !important;
  }
}
