.reffer-container-wrap {
  background-color: #f1f8ff;
  padding: 2% 5%;
}
.reffer-left-col {
  padding: 0 10rem 0 1rem !important;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.reffer-left-col button {
  border: none;
  font-size: 0.9rem;
  line-height: 1rem;
  font-weight: 600;
  background-color: #fbb040;
  border-radius: 5px;
  letter-spacing: 0.1em;
  color: #231f20;
  padding: 1rem 1.5rem !important;
  margin-right: auto;
}
.reffer-right-col {
  padding: 0 8% !important;
}
.reffer-left-col p {
  text-align: initial;
  font-size: 1rem;
  color: #626262;
}
.mobile-text {
  padding: 5% 10%;
}
.mobile-text button {
  border: none;
  font-size: 0.9rem;
  line-height: 1rem;
  font-weight: 600;
  background-color: #fbb040;
  border-radius: 5px;
  letter-spacing: 0.1em;
  color: #231f20;
  padding: 1rem 1.5rem !important;
  margin-right: auto;
}
.mobile-text p {
  font-size: 1rem;
  color: #626262;
  margin-bottom: 5%;
  padding: 1% 0%;
}
.desktop-text {
  display: flex !important;
  flex-direction: column;
  align-items: flex-start;
}

@media (max-width: 768px) {
  .reffer-left-col {
    padding: 0 4rem 0 0rem !important;
  }
  .desktop-text {
    display: none !important;
  }
  .reffer-right-col {
    padding: 0 !important;
  }
}
