.cart-main-section {
  background-color: #ffffff;
}
.cart-banner-main-div {
  font-family: "MontserratRegular";
  padding: 4%;
  color: #ffffff;
  background-color: #f5f5f5;
  background-image: url("../../assets/images/Cart/CartBanner.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.cart-banner-main-div h2 {
  margin: 0;
  font-weight: 800;
  font-size: 1.6rem;
  line-height: 2rem;
}
.cart-banner-main-div p {
  margin: 0;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1rem;
}
.cart-banner-main-div p a {
  color: #ffffff;

  text-decoration: none;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1rem;
}
.cart-banner-main-div p svg {
  margin: 0% 5px;
}
.order-section-title-div {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.order-section-title-div h4 {
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: #000000;
}
.order-section-title-div p {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  color: #000000;
}
.order-summery-card {
  /* padding: 2%; */
  /* padding-left: 0; */
  border: 1px solid #d9d9d9;
  border-radius: 10px;
}
.edit-item {
  /* Styles to be applied to the first child element */
  margin-left: auto !important;
}

.order-summery-card .image-col {
  padding-left: 0;
  max-height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.order-summery-card .image-col img {
  max-width: 100%;
  max-height: 100%;
}
.order-summery-card .description-col {
  text-align: start;
  padding: 2% 0;
}
.order-summery-card .description-col .summary-card-title {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  color: #000000;
  display: flex;
  justify-content: space-between;
}
.order-summery-card .description-col .summary-card-title s {
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 0.75rem;
  color: #000000;
}
.summary-card-details .summary-card-qnt {
  display: flex;
  justify-content: space-between;
}

.summary-card-qnt .summary-card-more-option {
  /* style={{ display: "flex", alignItems: "flex-end" }} */
  /* margin-right:15%; */
  display: flex;
  align-items: flex-end;
}
.summary-card-qnt .summary-card-item p {
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1rem;
  color: #817f7f;
  /* margin: 0; */
}
.summary-card-qnt .summary-card-item h6 {
  font-weight: 500;
  font-size: 0.85rem;
  line-height: 1rem;
  color: #000000;
  margin: 0;
}

.summary-card-qnt .summary-card-item h6.strong {
  font-weight: 700;
  font-size: 1rem;
}

.summary-card-more-option span {
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: right;
  text-decoration-line: underline;
  color: #00b3f7;
}

.order-summery-price {
  text-align: end;
}

.price-box .title {
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1rem;
  text-align: right;

  color: #817f7f;
}
.price-box .amount {
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 1rem;
  color: #000000;
}

.price-box .amount.strong {
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.25rem;
}

.cutomeHR {
  margin: 2%;
  opacity: 1;
  color: #000000;
}
.button-div button {
  background: #fbb040;
  border: none;
  border-radius: 0;
  color: #000000;
  padding: 3% 7%;
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 1rem;
}
.order-summery-card .description-col .summary-card-title svg {
  margin-right: 2%;
}
.cart-order-summary-section {
  padding: 0 8rem;
  padding-top: 4rem;
}

.delete-item,
.edit-item {
  border: none;
  padding: 0;
  background-color: transparent;
  margin: 0 0.5rem;
}
.delete-item:hover,
.edit-item:hover {
  border: none;
  padding: 0;
  background-color: transparent;
}
@media (min-width: 1400px) {
  /* .cart-order-summary-section {
    padding: 0% 8%;
    padding-top: 4%;
  } */
}
@media (max-width: 1024px) {
  .cart-order-summary-section {
    padding: 0% 4rem;
    padding-top: 2rem;
  }
}
@media (max-width: 768px) {
  .cart-order-summary-section {
    padding:  1rem;
    padding-top: 1rem;
  }
  .order-summery-card .description-col .summary-card-title svg {
    margin-right: 0%;
  }
  .summary-card-qnt .summary-card-more-option {
    width: max-content;
  }
  .order-section-title-div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
  .order-section-title-div #borderLeft {
    width: 30%;
  }

  .cart-order-summary-section .accordion-item {
    border: none;
    border-bottom: 1px solid #d9d9d9;
  }
  .cart-order-summary-section .accordion-item .accordion-button {
    background-color: transparent;
  }
  .button-div button {
    width: 100%;
  }
  .order-summery-card .description-col .summary-card-title h5 {
    font-size: 0.75rem;
    line-height: 0.95rem;
    margin: 0;
  }
  .order-summery-card .description-col .summary-card-title svg {
    padding: 1%;
  }
  .description-col hr {
    margin: 2% 0;
  }
  .summary-card-details .summary-card-qnt {
    justify-content: flex-start;
  }
  .summary-card-qnt .summary-card-item {
    padding: 2% 0%;
  }
  .summary-card-qnt .summary-card-item p {
    margin: 0%;
    width: max-content;
    font-size: 0.7rem;
    line-height: 13px;
  }
  .summary-card-qnt .summary-card-item h6 {
    margin: 0%;
    width: 100%;
    font-size: 0.75rem;
    line-height: 13px;
  }
  .summary-card-qnt .summary-card-item .amount {
    font-size: 0.9rem;
    /* line-height: 1.5rem; */
    font-weight: 500;
  }
  .summary-card-more-option span {
    margin: 0%;
    width: max-content;
  }
  .order-summery-card .image-col {
    padding-right: 0;
  }
  .summary-card-details-col {
    padding: 0 10%;
  }
  .summary-card-icon-col svg {
    padding: 30% 0%;
    padding-left: 10%;
  }
}
@media (max-width: 576px) {
  .order-summery-card .description-col .summary-card-title h5 {
    font-size: 0.75rem;
    line-height: 0.95rem;
    margin: 0;
  }
}
