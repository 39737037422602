.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #ff9900;
  width: 25px;
  height: 25px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}
.dark {
  border: 5px solid #494647;
  border-top: 5px solid #ff9900;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media (max-width: 1024px) {
}
@media (max-width: 768px) {
}
@media (max-width: 576px) {
}
