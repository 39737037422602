.product-main-section {
  background-color: #ffffff;
}
.product-banner-main-div {
  padding: 4%;
  color: #000000;
  background-color: #f5f5f5;
  background-image: url("../../assets/images/Product/ProductBanner.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.product-banner-main-div h2 {
  margin: 0;
  font-weight: 800;
  font-size: 1.6rem;
  line-height: 2rem;
  color: #ffffff;
}
.product-banner-main-div p a {
  margin: 0;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1rem;
  color: #000000;
  text-decoration: none;
}
.product-banner-main-div p svg {
  margin: 0% 1%;
}
.product-discription-div {
  text-align: left;
  margin: 0%;
}
.product-details-bullet-point {
  display: flex;
  align-items: center;
  margin-bottom: 5%;
}

.product-discription-div p {
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 1rem;
  color: #817f7f;
}
.product-discription-div p span {
  color: #000000;
}
.product-discription-div h4 {
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.4rem;
  margin: 0;
  color: #231f20;
}
.product-details-bullet-point p {
  padding-left: 5%;
}

.poduct-custom-section {
  text-align: left;
}

.product-variant-div h4 {
  font-family: "MontserratSemiBold";
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.75rem;

  color: #000000;
}
.small-image-heading p {
  padding: 1% 0%;
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 1rem;
  letter-spacing: 0.1em;
  color: #817f7f;
}
.small-image-heading {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.small-image-heading img {
  width: 20%;
}
.type-box {
  display: flex;
  align-items: center;
  background-color: #d8f1ff;
  padding: 2% 3%;
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 1rem;
  width: max-content;
  color: #000000;
}
.type-box .border-p {
  border-right: 2px solid #000000;
  margin: 1% 0%;
  height: 1rem;
}
.type-box span {
  margin: 0;
  width: max-content;
}

.product-rating {
  display: flex;
  align-items: baseline;
}
.product-rating p {
  padding: 1% 3%;
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 1rem;

  color: #817f7f;
}
.rating-star-container {
  display: flex;
}
.extra-small-text {
  font-weight: 400;
  font-size: 0.625rem;
  line-height: 0rem;
  color: #817f7f;
}
.variants-div p {
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 1rem;
  color: #000000;
  margin: 0;
}
.variants-div button {
  color: #000000;
  background-color: transparent;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  margin-right: 1%;
  padding: 1% 3%;
}
.variants-div button:hover {
  color: #000000;
  background-color: transparent;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
}

.variants-div .active {
  color: #000000 !important;
  border: 1px solid #fbb040 !important;
  background-color: #fbb040 !important;
}
.variant {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2% 0;
  flex-wrap: wrap;
}
.type-box h1 {
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.75rem;
  color: #000000;
  padding: 0%;
  padding-left: 1rem;
  width: max-content;
  margin: 0;
}
.type-box h1 span {
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1rem;
  color: #231f20;
}
.delivery {
  display: flex;
  justify-content: flex-start;
  padding: 1rem 0;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  cursor: pointer;
}
.delivery-option {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 2% 2%;
  color: #817f7f;
}
.delivery-option.active {
  color: #000000;
}
.delivery-option.active .delivery-icon {
  padding: 8% 2%;
  background-color: #d8f1ff;
  border-radius: 50%;
}
.delivery-option h5,
.delivery-option p {
  margin-bottom: 0;
  width: max-content;
}
.delivery-option h5 {
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 1rem;
  text-transform: uppercase;
}
.delivery-option p {
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 1rem;
}

.delivery-icon {
  padding: 8% 2%;
  background-color: #f5f5f5;
  border-radius: 50%;
}
.delivery-icon svg {
  padding: 4%;
  margin: 0 !important;
}

.total-amt {
  display: flex;
  align-items: center;
  padding: 3% 0%;
}
.total-amt h6 {
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 1rem;
  color: #817f7f;
  margin: 0;
  margin-right: 1rem;
}
.total-amt h2 {
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.75rem;
  color: #000000;
  margin: 0;
}
.total-amt h2 span {
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 1rem;
  color: #817f7f;
  margin: 0;
  margin-right: 1rem;
}
.cart-order-div {
  display: flex;
  padding: 4% 0;
}
.cart-order-div button {
  border: 1px solid #817f7f;
  background-color: transparent;
  border-radius: 5px;
  font-size: 0.8rem;
  line-height: 1rem;
  color: #817f7f;
  padding: 2% 5%;
  margin-right: 5%;
}
.cart-order-div button:hover {
  border: 1px solid #fbb040;
  background-color: #fbb040;
  border-radius: 5px;
  font-size: 0.8rem;
  line-height: 1rem;
  color: #000000;
}
/* .cart-order-div button.active {
  border: 1px solid #fbb040;
  background-color: #fbb040;
  border-radius: 5px;
  font-size: 0.8rem;
  line-height: 1rem;
  color: #000000;
} */

.additional-info {
  padding: 0;
}
.additional-info h4 {
  font-size: 1rem;
  line-height: 1.25rem;
  margin: 0;

  color: #231f20;
}
.additional-info p {
  margin: 2% 0;
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 1rem;
  color: #817f7f;
}
.additional-info p span {
  margin: 0;
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 1rem;
  color: #000000;
}

.section-other-variant hr {
  margin: 4% 0;
}
.section-other-variant-title {
  text-align: initial;
  margin-bottom: 5%;
  font-weight: 800;
  font-size: 1.5rem;
  line-height: 2rem;
}
.section-other-variant-title-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  align-items: center;
}
.section-other-variant-title-div a {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1rem;
  letter-spacing: 0.1em;
  text-decoration-line: underline;
  color: #000000;
}
.section-other-variant-title-div a:hover {
  color: #fbb040;
}
.section-other-variant-title-div h4 {
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1rem;
  margin: 0 !important;
}

.brand-card-div.other-variant {
  border: 1px solid #d9d9d9;
  border-radius: 10px;
}
.other-poduct-section {
  text-align: left;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  padding: 1rem 0.5rem;
}
.other-poduct-section h2 {
  margin: 0;
  padding: 2% 0% 0 5%;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.25rem;
  color: #231f20;
}
.other-card {
  padding: 1% 5%;
}
.other-card a {
  text-decoration: none;
}
.other-card h5 {
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 1rem;
  color: #231f20;
  margin: 0;
}
.other-card p {
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1rem;
  color: #626262;
  margin-bottom: 2%;
}
.brand-card-product {
  margin: 0%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.brand-card-product span {
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1rem;
  color: #000000;
}
.brand-card-product h3 {
  margin: 0;
  font-weight: 700;
  font-size: 1rem;
  line-height: 0.75rem;
  color: #000000;
}
.brand-card-product button {
  background-color: #00b3f7;
  border-radius: 5px;
  border: 1px solid #00b3f7;
  margin-left: auto;
  font-size: 0.9rem;
  padding: 0.5rem;
}
.brand-card-product button svg {
  width: 1rem;
}
.galaryBigImage {
  padding: 0 6%;
}

.image-gallery-slide-wrapper {
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  padding: 2% 1%;
}
.image-gallery-thumbnails .image-gallery-thumbnails-container {
  display: flex;
  justify-content: space-between;
  padding: 2% 0;
}
.image-gallery-thumbnails .image-gallery-thumbnails-container button {
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  width: 30%;
}
.image-gallery-thumbnails .image-gallery-thumbnails-container button img {
  padding: 0.5rem;
  min-height: 100px;
  max-height: 100px;
  width: auto;
}

.payment-option-check-box {
  display: flex;
  align-items: center;
  padding: 3% 0;
}
.payment-option-check-box select {
  width: auto;
  margin-left: 5%;
  margin-right: 2%;
}

.payment-option-check-box label {
  margin: 0;
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 1rem;

  color: #231f20;
}
.round-checkbox .form-check-input[type="checkbox"] {
  border-radius: 50%;
}
.round-checkbox label {
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 1rem;
  color: #231f20;
}

.product-mobile-carousal-item {
  border: 0.5px solid #d9d9d9;
  border-radius: 5px;
  padding: 5%;
  margin: 0% 5%;
  height: 100%;
  /* to center an image  */

  display: flex;
  justify-content: center; /* Horizontally center */
  align-items: center;
}
.product-mobile-carousal-item img {
  /* to center an image  */

  max-width: 100%;
  max-height: 100%;
  /* Optional: To prevent the image from stretching */
  object-fit: contain;
}

.popover {
  max-width: 199px !important;
}

.button-popover {
  border: 0px !important;
  background-color: #ffffff !important;
}
.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: 450px;
  min-height: 450px;
}
.payment-option-check-box .searchWrapper {
  max-width: 12rem;
}
.payment-option-check-box .multiSelectContainer input {
  width: 100%;
}
.delivery-lable{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}
@media (max-width: 768px) {
  .total-amt h2 {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
  .variants-div button {
    padding: 2% 4%;
    margin-top: 4%;
    margin-right: 4%;
  }
  .extra-small-text {
    width: max-content;
    font-weight: 400;
    font-size: 0.625rem;
    line-height: 0.65rem;
    color: #817f7f;
  }
  .variants-div p {
    width: max-content;
  }
  .product-discription-div.accordion {
    border-bottom: 1px solid #d9d9d9;
  }
  .product-discription-div .accordion-item {
    border: none !important;
  }
  .product-discription-div .accordion-item button {
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;
  }
  .product-discription-div .accordion-item button::after {
    color: #000000;
  }
  .product-discription-div .accordion-body {
    padding-left: 0;
    padding-right: 0;
  }
  .product-variant-div h4 {
    font-size: 1rem;
    line-height: 1.25rem;
  }
  .type-box {
    margin: 1rem 0;
  }
  .delivery {
    padding: 0% 2%;
  }
  .delivery-option {
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 2% 2%;
    color: #817f7f;
  }
  .delivery-option.active {
    color: #000000;
  }
  .delivery-option .delivery-icon {
    margin: 10%;
  }

  .delivery-option.active .delivery-icon {
    padding: 8% 2%;
    background-color: #d8f1ff;
    border-radius: 50%;
  }
  .delivery-option h5,
  .delivery-option p {
    margin: auto;
    margin-bottom: 5%;
  }
  .cart-order-div button {
    width: 100%;
    padding: 4% 5%;
  }
  .total-amt {
    padding: 0.75rem 0.5rem;
    align-items: flex-start;
    flex-direction: column;
  }
  .type-box h1 {
    font-size: 1.2rem;
    line-height: 1.25rem;
  }
  .type-box h1 span {
    font-weight: 400;
    font-size: 0.7rem;
    line-height: 0.9rem;
  }
}
@media (max-width: 576px) {
  .product-variant-div h4 {
    font-size: 1rem;
    line-height: 1.25rem;
  }
}
