.stats-row {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  padding: 0;
  margin: 0;
}
.stats-row::-webkit-scrollbar {
  height: 0.4rem;
  /* border: 1px solid #d5d5d5; */
}
.stats-row::-webkit-scrollbar-track {
  border-radius: 10px;
  height: 0.2rem;
  background-color: transparent;
  /* background: #d5d5d5; */
}
.stats-row::-webkit-scrollbar-thumb {
  border-radius: 10px;
  height: 0.2rem;
  background: #ff9900;
}
.section-row {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
}
.section-row .table-action-box .action-button.accept {
  font-size: 1rem;
  line-height: 1.25rem;
  font-family: "InterRegular";
  font-weight: 600;
  padding: 0.75rem 1.5rem;
  border-radius: 5px;
  background-color: #007bdb;
  border: none;
}
.stats-row h4 {
  padding: 0rem;
}
/* .onboarding .nav-tabs  {
  padding: 0 0.5rem;
} */
.onboarding .nav-tabs .nav-link {
  color: #626262;
}
.onboarding .nav-tabs .nav-link.active {
  color: #ff9900;
}
.onboarding .tab-content {
  padding: 0;
}
.onboarding .action-buttons {
  justify-content: start;
}
.onboarding .action-buttons button {
  margin-left: 0.8rem;
}

@media (max-width: 768px) {
  .stats-row {
    padding-bottom: 3rem;
  }
  .section-row .table-action-box .action-button.accept {
    font-size: 0.75rem;
    line-height: 1rem;
    padding: 0.5rem 0.75rem;
    border: none;
  }
}
@media (max-width: 576px) {
}
