/* Nav styles */
.space {
  width: 100%;
  padding: 2.5%;
  background: transparent;
}

.navbar-section {
  width: 100%;
  position: fixed;
  background-color: #ffffff;
  z-index: 10;
  border-top: 1px solid rgb(210, 210, 210);
}
.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.navbar-brand img {
  height: 192px;
  height: 40px;
}
.search-wrap {
  width: 100%;
  padding: 0% 5%;
  padding-left: 10%;
}
.search-container {
  padding: 0rem !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search-container input {
  border: 1px solid #d9d9d9 !important;
  border-left: 0px solid #d9d9d9 !important;

  border-radius: 5px 0px 0px 5px;
}
.search-dropdown {
  background-color: transparent !important;
  color: #000000 !important;
  border: 1px solid #d9d9d9 !important;
  border-right: none !important;
  padding: 0.5rem 1rem !important;
}
.search-dropdown svg {
  padding: 2%;
  margin: 10%;
}
.search-container .search-btn {
  border: 1px solid #fbb040 !important;

  border-radius: 0px 5px 5px 0px;
}

.navbar-container .link-warp {
  width: 100%;
  padding-left: 5%;
}
.navbar-container .nav-icons {
  display: flex;
  align-items: center;
}
.navbar-container .nav-icons .mobile {
  margin: 0.7rem;
}

.about-desktop-menu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.about-mobile-menu {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.navSocialLiImage {
  padding: 5px;
}
.navSocialLi {
  background-color: #004197;
  border-radius: 50%;
}
.actionNavLi {
  background-color: transparent !important;
  border: none !important;
  border-radius: 50px !important;
}
/* .ml-auto{
    margin-left: auto;
} */
#input-group-dropdown-1 {
  padding: 0rem 2rem;
  background-color: #ffffff !important;
  color: #000000 !important;
  border-top: 1px solid #d4d4d4 !important;
  border-bottom: 1px solid #d4d4d4 !important;
  border-left: 1px solid #d4d4d4 !important;
  border-right: 0px solid #d4d4d4 !important;
  border-radius: 5px 0 0 5px !important;
}
.dropdown-toggle::after {
  display: none !important;
}
#borderRight {
  border: 1px solid #000000;
  margin: 8px 0;
}
.search-box {
  border: 1px solid #7c7e82 !important;
}

.search-btn {
  padding: 0 1rem;
  border: #fbb040;
  background-color: #fbb040 !important ;
  color: #ffffff !important;
}

.desktop-nav-item {
  display: flex !important;
  align-items: center;
}
.desktop-nav-item .nav-link-color.blackBg {
  padding: 0.5rem 1rem;
  background-color: #231f20;
  color: #ffffff;
}
.desktop-nav-item .nav-link-color.brandBg {
  padding: 0.5rem 1rem;
  background-color: #fdc345;
  color: #000000;
}
.nav-link-color {
  color: #000000;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 400;
  margin: 1rem;
  width: max-content;
}

.about-us .nav-link-color.active,
.aboutus-ofcanvas .nav-link-color.active {
  color: #fdc345;
}

.about-us.scrolled .nav-link-color.active,
.aboutus-ofcanvas .nav-link-color.active {
  color: #ffffff;
}

.about-us .nav-link-color.active.white,
.aboutus-ofcanvas .nav-link-color.active {
  color: #ffffff;
}
.modal-login-btn {
  background-color: transparent !important;
  border: none !important;
  color: #000000 !important;
}
.modal-login-btn:hover {
  background-color: transparent !important;
  border: none !important;
  color: #fdc345 !important;
}
.nav-item:hover {
  color: #fdc345;
  text-decoration: none;
}
.about-us .nav-item {
  font-weight: 600;
  padding: 0 2%;
}
.about-us .nav-item:hover {
  color: #fdc345;
  text-decoration: none;
}
.about-us.scrolled .nav-item:hover {
  color: #ffffff;
  text-decoration: none;
}
.about-us .nav-item.white:hover {
  color: #ffffff;
  text-decoration: none;
}
.brand-hover:hover {
  color: #fdc345;
  text-decoration: none;
}
.cartBadge {
  border-radius: 30px !important;
  position: absolute;
}
.about-us {
  background-color: transparent;
  background-image: linear-gradient(to right, transparent, transparent);
  transition: background-image 1s ease-in-out, color 1s ease-in-out;
}
.about-us.scrolled {
  background-image: linear-gradient(to right, #fdc345, #ffed88);
  color: #ffffff;
}
.burger-menu-wrap {
  margin-left: auto;
}
.burger-menu-wrap button {
  background-color: transparent;
  border: none;
}
.aboutus-ofcanvas {
  background-image: linear-gradient(to right, #fdc345, #ffed88);
  clip-path: polygon(0% 0%, 0% 100%, 100% 75%, 100% 0%);
  height: 100%;
  width: 100% !important;
}

.aboutus-backdrop.show {
  width: 100%;
  height: 100%;
  opacity: 1 !important;
  background-color: #ffffff;
}
.aboutus-ofcanvas .offcanvas-header .btn-close {
  padding: 8% !important;
  margin-left: auto !important;
}
@media (max-width: 1024px) {
  .about-us {
    padding: 1rem 0.5rem !important;
  }
  .mobile.burger-menu-wrap button {
    padding: 0;
  }
  .about-us .nav-item {
    font-weight: 600;
    padding: 0%;
  }
  .search-wrap {
    padding: 0%;
  }
  .search-container .input-group {
    flex-wrap: nowrap;
  }
}

@media (max-width: 768px) {
  .navbar-brand img {
    height: 125px;
    height: 25px;
  }

  .aboutus-ofcanvas .desktop-nav-item .nav-link-color.blackBg {
    padding: 0.5rem 0.75rem;
    background-color: #231f20;
    color: #ffffff;
    font-size: 1rem;
  }
  .desktop-nav-item button,
  .desktop-nav-item a {
    font-size: 0.7rem;
    margin: 0;
    padding: 0.5rem 0.5rem;
    color: #ffffff;
    font-size: 0.7rem;
  }
  .aboutus-ofcanvas .nav-link-color {
    font-size: 1rem;
    margin: 0rem;
    padding: 1rem;
  }
  .search-container {
    padding: 0.7rem 0.5rem 0.7rem 0.5rem !important;
  }
  .space {
    padding: 1.8rem;
    background: transparent;
  }

  .search-dropdown {
    border: none !important;
    padding: 0.5rem 0.5rem !important;
    font-size: 0.7rem;
  }
}
@media (max-width: 576px) {
  /* .desktop {
    display: none;
  }
  .mobile {
    display: block;
    width: 100%;
  } */

  .space {
    padding: 1.8rem;
    background: transparent;
  }

  /* .prompt-card-img img {
    width: auto !important;
    margin-left: auto;
    margin-right: auto;
  } */
}

/* footer style */
.footer {
  background-color: #231f20;
  color: #ffffff;
  padding-top: 3%;
  box-shadow: 0px -1px 14px rgba(0, 0, 0, 0.25);
  padding: 3% 8%;
}
.copyright {
  margin-right: 0;
  padding: 1.5rem 0;
  border-top: 2px solid #d9d9d9;
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 1.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.urldiv {
  font-weight: 500;
  font-size: 1.2rem;
  text-decoration: none;
}
.footer a {
  text-decoration: none;
  color: #ffffff;
}
.footer a:hover {
  color: #fbb040;
}
.footer-logo-col {
  font-size: 1.2rem;
}

.footer .linkbutton {
  text-decoration: none;
  color: #ffffff;
  background-color: transparent;
  padding: 0;
  border: none;
}
.footer .linkbutton:hover {
  color: #fbb040;
  background-color: transparent;
  padding: 0;
  border: none;
}

.footer-parha {
  font-weight: 400;
  font-size: 0.8rem;
  padding: 2rem 2rem 2em 0;
  line-height: 18px;
}

.footer ul {
  list-style-type: none;
  padding: 1rem 0;
}
.footer h6 {
  font-size: 0.9rem;
  text-align: start;
  font-weight: 600;
  color: #fbb040;
  margin: 0;
}
.footer ul li {
  font-weight: 400;
  font-size: 0.9rem;
  margin: 1rem 0;
  width: max-content;
  display: flex;
  align-items: center;
}
.footer ul li svg {
  margin: 0 10px;
}
.footer-details-col {
  text-align: start;
  padding: 0;
}

.small-p {
  font-size: 1rem;
  font-weight: 300;
}
.footer-details-col svg {
  margin-left: 0px !important;
  margin-right: 10px !important;
}
#borderSeprater {
  border: 1px solid #ffffff !important;
  height: inherit;
  margin: 5px 0;
}
.footer-login-tab {
  font-size: 0.9rem;
  color: #ffffff;
  margin: 0;
}
.footer-input {
  color: #d9d9d9 !important;
  background-color: transparent !important;
  border: 1px solid #f5f5f5 !important;
  border-radius: 5px !important;
}
.footer-input::placeholder {
  color: #d9d9d9 !important;
}
.footer-item-register {
  font-weight: 400;
  font-size: 0.8rem;
  color: #d9d9d9 !important;
  letter-spacing: 0.1rem;
  font-style: italic;
  background: none;
  border: none;
  padding: 0;
}
.footer-item-register:hover,
.footer-item-register:active {
  background: none !important;
  border: none !important;
}
.login-btn {
  width: 100%;
  padding: 0.5rem 1rem;
  background-color: transparent;
  color: #ff9900;
  border: 1px solid #ff9900;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.login-btn:hover {
  background-color: transparent;
  color: #ff9900;
  border: 1px solid #ff9900;
}
.login-btn:active {
  background-color: transparent;
  color: #ff9900;
  border: 1px solid #ff9900;
}
.btn-left {
  margin-left: auto;
}
.login-loder-btn {
  padding: 0.3rem 1rem !important;
  background-color: transparent;
  color: #ff9900;
  border: 1px solid #ff9900;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-loder-btn:hover {
  background-color: transparent;
  color: #ff9900 !important;
  border: 1px solid #ff9900;
}
.login-loder-btn:active {
  background-color: transparent !important;
  color: #ff9900 !important;
  border: 1px solid #ff9900 !important;
}

.otp-timer {
  display: flex;
  justify-content: space-between;
}
.otp-timer p {
  color: #6f6f6f;
  font-style: italic;
  font-size: 0.9rem;
  font-weight: 600;
  margin: 0;
}
.resend-otp-btn {
  cursor: pointer;
  border: none !important;
  padding: 0 0.5rem !important;
  background-color: transparent !important;
  font-style: italic;
  font-size: 0.9rem !important;
  font-weight: 600 !important;
  width: max-content !important;
}
.resend-otp-btn:hover {
  font-weight: 700 !important;
}

.form-check-input:checked {
  background-color: #ff9900 !important;
  border-color: #ff9900 !important;
}
.square-checkbox .form-check-input[type="checkbox"] {
  border-radius: 0 !important;
}
.modal-header p {
  cursor: pointer;
}
.term label {
  color: #817f7f !important;
}

.modal-custom-class button.reset {
  height: 100%;
  background-color: #ffffff;
  border: 1px solid #817f7f;
  margin-left: auto;
}
.modal-custom-class button.login {
  background-color: #fbb040;
  border: 1px solid #fbb040;
  margin-left: 0.5rem;
}

.error-msg {
  color: #ff0000;
}
.redStreSpan {
  color: #ff0000;
}
.positiveMsg {
  color: #07cf83;
}
.modal-custom-class .left {
  padding-left: 0;
}
.modal-custom-class .right {
  padding-right: 0;
}

.socail-icons {
  display: flex;
  align-items: center;
}
.socail-icons a {
  margin-right: 1rem;
}

.footer-logo {
  height: 59px;
  width: 210px;
}

.seller-tag-div {
  display: flex;
  justify-content: space-between;
}
.copyright p {
  margin: 0;
}
.pointer {
  cursor: pointer;
}
.login-active {
  color: #fbb040;
}

/* form Css  */

.modal-custom-class .modal-content {
  padding: 5%;
}
.modal-custom-class .modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  padding-bottom: 4%;
  border: none;
}
.modal-custom-class .modal-header h4 {
  margin: 0;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: #000000;
}
.modal-custom-class .modal-body {
  padding: 0;
}
.modal-custom-class .modal-body h5 {
  margin: 0;
  text-align: center;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2rem;
  color: #000000;
}
.modal-custom-class .modal-header p {
  margin: 0;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
  color: #248cc6;
}

.hidden {
  display: none;
}
.modal-custom-class label,
.modal-custom-class label p,
.modal-custom-class label a {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
  color: #626262;
}
.modal-custom-class label a {
  text-decoration: underline;
  text-decoration-color: #626262;
  text-decoration-thickness: 1px;
}
.modal-custom-class input {
  background-color: #f8f8f8;
  border: 1px solid #d9d9d9;
  border-radius: 0;
}
.modal-custom-class .multiSelectContainer input {
  margin: 0;
}
.modal-custom-class.login-from input[type="text"] {
  padding: 3% 5%;
}
.modal-custom-class textarea {
  background-color: #f8f8f8;
  border: 1px solid #d9d9d9;
  border-radius: 0;
}
.modal-custom-class.login-from textarea[type="text"] {
  padding: 3% 5%;
}
.modal-custom-class .small_logo_img {
  max-width: 5rem;
  padding: 0.5rem 0;
  margin-right: 1rem;
}
.modal-custom-class .action-buttons {
  padding-top: 1.25rem;
  display: flex;
  align-items: flex-end;
  align-items: center;
}
.modal-custom-class .action-buttons button {
  font-weight: 600;
  font-size: 0.9rem;
  line-height: 1rem;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #231f20;
  border-radius: 5px;
  padding: 1rem 2.5rem;
}

.modal-custom-class .action-buttons button:first-child {
  /* Styles to be applied to the first child element */
  margin-left: auto;
}

.modal-custom-class .action-buttons .close-btn {
  height: 100%;
  background-color: #626262;
  border: 1px solid #626262;
  color: #ffffff;
}

.modal-custom-class .action-buttons .rest-btn {
  height: 100%;
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  margin-left: auto;
  color: #231f20;
}
.modal-custom-class .action-buttons .accept-btn {
  height: 100%;
  background-color: #039487;
  border: 1px solid #039487;
  color: #ffffff;
}
.modal-custom-class .action-buttons .reject-btn {
  height: 100%;
  background-color: #ff4f4f;
  border: 1px solid #ff4f4f;
  color: #ffffff;
}
.modal-custom-class .action-buttons .submit-btn {
  background-color: #fbb040;
  border: 1px solid #fbb040;
  color: #ffffff;
  margin-left: 0.5rem;
}

.modal-custom-class .submit-btn-loading {
  width: auto;
  padding: 0.5rem 1rem;
  background-color: transparent;
  color: #ff9900;
  border: 1px solid #ff9900;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-custom-class .submit-btn-loading:hover {
  background-color: transparent;
  color: #ff9900 !important;
  border: 1px solid #ff9900;
}
.modal-custom-class .submit-btn-loading:active {
  background-color: transparent !important;
  color: #ff9900 !important;
  border: 1px solid #ff9900 !important;
}
.modal-custom-class span.input-group-text {
  border-radius: 0;
}
.loading {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #ff9900;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}
/* .btn:hover {
  background-color: transparent;
  border-color: transparent;
  background-color: var(--bs-btn-hover-bg);
   border-color: var(--bs-btn-hover-border-color); 
 } */
.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  background-color: transparent;
  border-color: transparent;
  color: #515151;
}
.modal-custom-class {
  padding: 0rem;
}
.title-button-row h2 {
  width: max-content;
  font-family: "MontserratRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: #231f20;
  padding: 0;
  text-transform: uppercase;
  padding-left: 0.75rem;
}
.form-row {
  background-color: #ffffff;
  padding: 1rem 0.5rem;
  border-radius: 0.5rem;
  text-align: left;
}
.redirect-btn {
  background-color: #fbb040;
  border: 1px solid #fbb040;
  color: #ffffff;
  width: max-content;
}
.redirect-btn:hover,
.redirect-btn:active {
  background-color: #FFFFFF;
  border: 1px solid #fbb040;
  color: #000000;
}
@media (max-width: 992px) {
  .footter-link-row {
    margin: 0;
    padding: 10% 0;
  }
}
@media (max-width: 768px) {
  .modal-custom-class {
    padding: 0.5rem 0;
  }
  .copyright p {
    margin: 5% 0;
  }
  .footer {
    padding: 1.5rem 1rem;
  }
  .footer-logo {
    height: 35.35px;
    width: 140.54px;
  }

  .footer-link-col {
    padding-left: 0 !important;
  }
  .copyright {
    padding: 2% 0% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .copyright img {
    margin: 5% 0;
  }
  .modal-custom-class .action-buttons button {
    padding: 2% 6%;
  }
  .form-row {
    padding: 0.5rem;
  }
  .title-button-row h2 {
    padding: 0;
  }
}
@media (max-width: 576px) {
  .footer-login-tab {
    font-size: 0.8rem;
  }
}
