.stat-card {
  width: max-content;
  background: transparent;
  padding: 0;
  border: none;
}
.stat-card-row {
  width: max-content;
  text-align: left;
  padding: 1.25rem 0.75rem;
  margin: 0.5rem;
  margin-left: 0;
  border: none;
  background: #ffffff;
  border-radius: 0.6rem;
}

.text-column h6 {
  font-size: 1.25rem;
  font-family: "RobotoRegular";
  color: #231f20;
  line-height: 1.5rem;
  margin-bottom: 1rem;
}
.text-column p {
  font-size: 1rem;
  font-weight: 600;
  font-family: "RobotoRegular";
  color: #007bdb;
  line-height: 1.25rem;
  margin-bottom: 1rem;
}
.text-column div {
  font-size: 1rem;
  font-family: "RobotoRegular";
  color: #faaf43;
  line-height: 1.25rem;
  width: max-content;
  padding: 0;
}
.img-column {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.img-round-box {
  padding: 0.5rem;
  border-radius: 50%;
  background-color: #d9d9d9;
}
.stat-percent-div {
  display: flex;
  align-items: center;
}
.stat-percent-div svg {
  margin-right: 0.5rem;
}

@media (max-width: 768px) {
  .stat-card {
    width: 100%;
  }
  .stat-card-row {
    /* width: 100%; */
    padding: 1rem 0.75rem;
    margin: 0.5rem 0.5rem;
    margin-left: 0;
  }

  .text-column h6 {
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin-bottom: 0.8rem;
  }
  .text-column p {
    font-size: 1rem;
    line-height: 1.25rem;
    margin-bottom: 0.8rem;
  }
  .text-column div {
    font-size: 1rem;
    line-height: 1.25rem;
  }

  .img-round-box {
    padding: 0.7rem;
    border-radius: 50%;
    background-color: #d9d9d9;
  }
}
@media (max-width: 576px) {
}
