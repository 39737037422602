.calculator {
  background-color: #ffffff;
  padding: 1.5rem;
}
.new-calculation {
  background-color: #ffffff;
  padding: 0.5rem;
}
.calculator .nav-underline {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  overflow-x: scroll;
  padding: 1rem 0;
  border: none;
}
.calculator .nav-underline::-webkit-scrollbar {
  display: none;
}
.calculator .nav-underline::-webkit-scrollbar-track {
  display: none;
}
.calculator .nav-underline::-webkit-scrollbar-thumb {
  display: none;
}
.calculator .nav-underline .nav-link {
  color: #626262;
  width: max-content;
  margin-bottom: 1rem;
  margin-right: 1rem;
  margin-left: 0;
}
.calculator .nav-underline .nav-link.active {
  color: #ff9900;
}
.calculator .tab-content {
  padding: 0;
}

.redClr {
  color: red;
}

.new-calculation .action-buttons {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
}
.new-calculation .action-buttons button {
  margin-left: 0.8rem;
  border: none;
  border-radius: 0.25rem;
  background-color: #ff9900;
  border: 1px solid #ff9900;
}

.new-calculation .action-buttons button:hover {
  background-color: #ff9900d2;
}
.new-calculation .action-buttons button.reset-btn {
  background-color: #626262;
  border: 1px solid #626262;
}
.new-calculation .action-buttons button.reset-btn:hover {
  background-color: #515151;
}
.calculator h5 {
  font-family: "RobotoRegular";
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.5rem;
  padding-left: 0;
  margin-bottom: 1rem;
}
.calculation-form .input-grop-calculation {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.calculation-form .input-grop-calculation.flexColumn {
  flex-direction: column;
  align-items: start;
}
.calculation-form .input-grop-calculation label {
  color: #626262;
  font-family: "InterRegular";
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
  margin: 0;
  text-align: left;
}
.calculation-form .input-grop-calculation input {
  width: 50%;
  border: none;
  border-bottom: 1px solid #a0a0a0;
  border-radius: 0;
  padding: 0;
  /* margin-left: 1rem; */
}
.calculation-form .input-grop-calculation.flexColumn input {
  margin-left: 0rem;
  border: none;
}
.calculation-form .input-grop-calculation input:disabled {
  background-color: #ebe7e7;
  border-bottom: 1px solid #ebe7e7;
}
.calculation-form .input-grop-calculation input:focus {
  box-shadow: none;
}
.coverRow {
  min-width: 50rem;
}
.setColInputs {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.scrollXsection {
  padding: 0.5rem 0;
}
.col-padding {
  padding: 0.5rem 0;
}
.admin-panel-section .new-calculation h4 {
  width: max-content;
}
.raw-matrial-cost-cover {
  display: flex;
  align-items: center;
}

.raw-matrial-cost-cover .input-grop-calculation {
  width: max-content;
}

.pdf-view-hide {
  display: none;
}
.pdf-view-unhide {
  display: block;
  visibility: none;
}

.new-calculation .action-buttons .pdf-download-btn {
  margin-left: 0.8rem;
  padding: 0.5rem 1rem;
  text-align: center;
  border-radius: 0.25rem;
  background-color: #ff9900;
  border: 1px solid #ff9900;
  color: #ffffff;
  text-decoration: none;
}
.new-calculation .accordion-item {
  border: none !important;
}
.new-calculation .accordion-button {
  padding: 1rem 0.25rem;
}
.new-calculation .accordion-body {
  padding: 0;
}
.new-calculation .accordion-button{
  box-shadow: none;
}
@media (max-width: 1024px) {
  .scrollXsection {
    overflow-x: scroll;
  }
}
@media (max-width: 768px) {
  .calculator {
    padding: 1rem;
  }

  .calculation-form .input-grop-calculation.flexColumn {
    flex-direction: row;
    align-items: start;
  }
  .calculator h5 {
    font-size: 1rem;
    line-height: 1.25rem;
  }
  .monthly-expenses-row .col-md-4 {
    padding: 0;
  }
  .tab-content .col-lg-4,
  .tab-content .col-lg-2 {
    padding: 0;
  }
}
