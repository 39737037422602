.support-main-section {
  background-color: #f8f8f8;
}
.support-banner-main-div {
  padding: 4%;
  color: #626262;
  background-color: #f1f8ff;
  /* background-image: url("../../assets/images/Brands/BrandHomeBanner.png"); */
  background-repeat: no-repeat;
  background-size: cover;
}
.support-banner-main-div h2 {
  margin: 0;
  font-size: 1.6rem;
  line-height: 2rem;
  color: #000000;
  font-family: "MontserratRegular";
  font-weight: 800;
}
.support-banner-main-div p {
  margin: 0;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1rem;
}
.support-main-section p a {
  /* margin: 0; */
  color: #000000;
  text-decoration: none;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1rem;
  font-family: "MontserratRegular";
}
.support-conatiner {
  text-align: left;
  background-color: #f8f8f8;
}
.support-conatiner .form-col {
  background-color: #ffffff;
  padding: 0.25rem 2rem;
}
.support-conatiner .img-col  {
  padding: 0 0.5rem;
}
.support-conatiner .img-col img {
  width: 100%;
}
.support-conatiner .form-col input {
  background-color: #ffffff;
}

.support-conatiner .form-col textarea {
  background-color: #ffffff;
}
@media (max-width: 768px) {
  .support-conatiner .img-col  {
    padding: 0;
  }
  .support-conatiner .form-col {
    margin-top: 1rem;
    padding: 0.5rem 1rem;
  }
}
