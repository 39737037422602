.cart-main-section {
  background-color: #ffffff;
}
.cart-banner-main-div {
  font-family: "MontserratRegular";
  padding: 4%;
  color: #ffffff;
  background-color: #f5f5f5;
  background-image: url("../../assets/images/Cart/CartBanner.png");
  background-repeat: no-repeat;
  background-size: cover;
}

/* .order-summery-card .image-col {
  padding-left: 0;
} */

.summary-card-details .summary-card-qnt {
  display: flex;
  justify-content: space-between;
}

.summary-card-qnt .summary-card-more-option {
  /* style={{ display: "flex", alignItems: "flex-end" }} */
  /* margin-right:15%; */
  display: flex;
  align-items: flex-end;
}
.summary-card-qnt .summary-card-item p {
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1rem;
  color: #817f7f;
  /* margin: 0; */
}

.summary-card-more-option span {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  text-decoration-line: underline;
  color: #00b3f7;
}

.order-summery-price {
  text-align: end;
}

.price-box .title {
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1rem;
  text-align: right;

  color: #817f7f;
}

.cutomeHR {
  margin: 2%;
  opacity: 1;
  color: #000000;
}
.button-div button {
  background: #fbb040;
  border: none;
  border-radius: 0;
  color: #000000;
  padding: 1rem 1.5rem;
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 1rem;
}
.order-summery-card .description-col .summary-card-title svg {
  margin-right: 2%;
}
.amount-display-row h1 {
  font-family: "RobotoRegular";
  font-size: 2.25rem;
  font-weight: 500;
  line-height: 3rem;
  color: #ed752e;
}
.amount-display-row p {
  font-family: "RobotoRegular";
  font-size: 0.75rem;
  font-style: italic;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0.05em;
  margin: 0;
}
.checkout .order-section-title-div p {
  color: #00b3f7;
  margin: 0;
}
@media (max-width: 768px) {
  .checkout .order-section-title-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
  .checkout .filter-options p {
    line-height: 1rem;
    padding: 1rem 0.5rem;
    text-align: start;
  }
}
@media (max-width: 576px) {
}
