.about-us-become-a-seller-reverse {
  background-image: url("../../assets//images/greyBackgroud.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 138px;
  transform: scaleY(-1);
}
.about-become-a-seller-brand,
.about-seller-grow-wrap,
.aboutus-seller-help-map,
.about-us-become-a-seller-onboarding-features {
  background-color: #f0f1f1;
}
.about-become-a-seller-brand p {
  font-family: "InterRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: #000000;
}
.about-us-become-a-seller {
  background-image: url("../../assets//images/greyBackgroud.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 138px;
}
.about-us-become-a-seller-container .truck-img {
  width: 100%;
  height: auto;
}
.section-title-seller {
  font-family: "MontserratBold";
  font-size: xx-large;
}
.section-title-seller img {
  vertical-align: baseline;
}
.section-title-seller span {
  color: #fdc345;
}
.about-us-become-a-text-col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.about-us-become-a-text-col.right h4 {
  text-align: left;
}
.about-us-become-a-text-col.right h5 {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.5rem;
  color: #000000;
}

.about-us-become-a-text-col.right p {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.5rem;
  text-align: left;
  color: #75809e;
}

.about-us-become-a-seller-get-tools h4 {
  font-family: "InterRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: #000000;
}
.about-us-become-a-seller-get-tools p {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #75809e;
  padding: 0 15%;
}

.about-us-become-a-seller-get-tools .get-tools-col {
  padding: 1%;
}

.about-us-become-a-seller-get-tools .get-tools-col .get-tools-card {
  background: #ffffff;
  box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 10%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.about-us-become-a-seller-get-tools .get-tools-col .get-tools-card img {
  width: 100%;
  margin: auto;
}
.about-us-become-a-seller-get-tools .get-tools-col .get-tools-card h6 {
  font-family: "InterRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 0.8rem;
  line-height: 1.6rem;
  color: #000000;
  margin-top: 5%;
}

.about-us-become-a-seller-how-its-work h4 {
  font-family: "InterRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.6rem;
  margin-bottom: 5%;
  color: #000000;
}

.how-its-work-card .svg-wrap {
  background: #ffffff;
  box-shadow: 0px 0px 13px 2px rgba(0, 0, 0, 0.11);
  border-radius: 6px;
  padding: 2rem 1rem;
  margin: 0%;
  margin-bottom: 1rem;
}
.how-its-work-card h6 {
  font-family: "InterRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.25rem;
  margin: 5% 0%;
  color: #000000;
}
.how-its-work-card p {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #000000;
}
.direction-to img {
  margin-top: 2rem;
}
.direction-to.left img {
  transform: rotate(180deg);
}
.direction-to.down img {
  transform: rotate(90deg);
  padding: 20% 0%;
  margin: 20% 0%;
}

.about-us-become-a-seller-onboarding-features h4 {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #75809e;
  text-align: center;
}
.about-us-become-a-seller-onboarding-features .onboarding-features-col {
  margin-bottom: 1rem;
}
.about-us-become-a-seller-onboarding-features .onboarding-features-card {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  height: 100%;
}
.about-us-become-a-seller-onboarding-features .onboarding-features-card h6 {
  font-family: "InterRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 0.8rem;
  line-height: 1.1rem;
  color: #000000;
  padding: 2rem 0;
  padding-bottom: 0;
}
.about-us-become-a-seller-onboarding-features .onboarding-features-card ul {
  padding: 1rem 2rem;
  text-align: left;
  list-style-type: none;
  margin: 0;
}
.about-us-become-a-seller-onboarding-features .onboarding-features-card ul li {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.1rem;
  color: #54595f;
  margin-bottom: 0.5rem;
}

.how-its-work-col {
  padding: 0;
}

.how-its-work-card.big{
  margin: 0 3rem;
}

@media (max-width: 1024px) {
  .how-its-work-card.big{
    margin: 0 2rem;
  }
}
@media (max-width: 992px) {
  .about-us-become-a-seller-onboarding-features .onboarding-features-card h6 {
    padding: 5% 0%;
  }
  .about-us-become-a-seller-onboarding-features .onboarding-features-card ul {
    padding: 5% 10%;
  }
  .direction-to.down img {
    padding: 0%;
    margin: 0%;
    margin-top: 1%;
    padding-top: 1%;
  }
  .how-its-work-card {
    padding: 5%;
  }
  .how-its-work-card .svg-wrap {
    margin: 0% 25%;
    margin-bottom: 5%;
  }
  .how-its-work-card h6 {
    margin: 1% 0%;
  }
  .how-its-work-card p {
    margin-bottom: 5%;
  }
  .direction-to img {
    transform: rotate(90deg);
    margin: 1%;
    padding: 1%;
  }
  .direction-to.left img {
    transform: rotate(90deg);
  }
  .aboutus-seller-kpi .left,
  .aboutus-seller-tech-details .left {
    padding: 5% 10%;
  }
  .aboutus-seller-kpi .right,
  .aboutus-seller-tech-details .right {
    padding: 5% 10%;
  }
}
@media (max-width: 768px) {
  .about-us-become-a-seller-onboarding-features .onboarding-features-col {
    padding: 0;
  }
  .aboutus-seller-kpi .kpi-text-col .section-link,
  .aboutus-seller-tech-details .tech-details-text-col .section-link {
    margin-left: auto;
    margin-right: auto;
  }
  .about-us-become-a-seller-onboarding-features .onboarding-features-card ul {
    padding: 2% 5%;
  }
  .about-us-become-a-seller {
    height: 100px;
  }
  .about-us-become-a-text-col {
    padding-bottom: 1rem;
  }
  .about-us-become-a-seller-get-tools p {
    padding: 0 3%;
    text-align: left;
  }

  .about-us-become-a-seller-get-tools h4 {
    text-align: left;
    padding-left: 3%;
  }
  .about-us-become-a-text-col.right h5 {
    text-align: left;
  }
}
@media (max-width: 576px) {
}
