.bhree-custom-table {
  height: 100%;
  width: 100%;
}

.bhree-custom-table .MuiPaper-root {
  padding: 0.5rem;
  height: 100%;
  width: 100%;
}
.bhree-custom-table .MuiPaper-root ::-webkit-scrollbar {
  height: 0.3rem;
  width: 100%;
  border: 1px solid #d5d5d5;
}
.bhree-custom-table .MuiPaper-root ::-webkit-scrollbar-track {
  border-radius: 0;
  height: 0.3rem;
  background: #eeeeee;
}
.bhree-custom-table .MuiPaper-root ::-webkit-scrollbar-thumb {
  border-radius: 0;
  width: 10px;
  height: 1rem;
  background: #ff9900;
}

.bhree-custom-table .MuiTableHead-root tr,
.bhree-custom-table .MuiTableBody-root tr {
  background-color: transparent;
}

.bhree-custom-table .MuiTableHead-root tr th {
  font-family: "InterRegular";
  font-size: 1rem;
  line-height: 1.25rem;
  color: #626262;
  padding: 0.5rem;
  width: max-content;
}

.bhree-custom-table .MuiTableBody-root tr td {
  padding: 0.5rem;
  width: max-content;
  font-family: "InterRegular";
  font-size: 1rem;
  line-height: 1.25rem;
  color: #626262;
  background-color: #ffffff;
}
